import { Component, Vue } from "vue-property-decorator";
import { getPosition } from "@/module/tools";
interface RegionData {
  // 当前定位的地区数据
  municipalityRegion: boolean;
  regionChnName: string;
  regionCode: string;
  regionCityCapAlp?: string;
}

@Component
export default class Mixin extends Vue {
  private mix_isCalledGetLocation = false; // 是否已调用过获取定位
  // 获取默认城市
  public mix_ajaxQueryDefaultCity(): Promise<RegionData> {
    return new Promise((resolve, reject) => {
      this.$api
        .slget("cgi_commonDictQueryByKeyAndName", {
          key: "DEFAULT_CITY",
          name: "DEFAULT_CITY_NAME",
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            resolve(JSON.parse(res.SZ_BODY.cacheDto.content));
          } else {
            this.$toast(res.SZ_HEAD.RESP_MSG);
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // 获取定位信息 仅执行一次
  public mix_ajaxQueryCityByLocationOnce(): Promise<RegionData> {
    return new Promise((resolve, reject) => {
      if (this.mix_isCalledGetLocation) {
        reject({ msg: "重复调用", code: "-1" });
      }
      this.mix_isCalledGetLocation = true;

      // 获取用户当前位置
      getPosition()
        .then(async (res) => {
          this.mix_ajaxGetCityByLocation(res.latitude, res.longitude)
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          console.log("[用户授权地理信息失败]", err);
        });
    });
  }

  // 通过定位获取当前城市
  public mix_ajaxGetCityByLocation(latitude, longitude): Promise<RegionData> {
    return new Promise((resolve, reject) => {
      this.$api
        .slget("getCityByLocation", {
          latitude,
          longitude,
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            const { DEFAULT_CITY, LOCATION_CITY } = res.SZ_BODY;
            let data;
            if (LOCATION_CITY) {
              data = LOCATION_CITY;
            } else if (DEFAULT_CITY.length > 0) {
              data = DEFAULT_CITY[0];
            }

            if (data) {
              resolve({
                regionChnName: data.regionChnName,
                regionCityCapAlp: data.regionCapAlp,
                regionCode: data.regionCode,
                municipalityRegion: data.isMunicipality === 1,
              });
            } else {
              reject({ msg: "无法定位到具体城市", code: "-2" });
            }
          } else {
            // this.$toast(res.SZ_HEAD.RESP_MSG)
            reject({ msg: "接口异常", code: "-2" });
          }
        });
    });
  }
}
