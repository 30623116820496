/* ------------------------------------------- template
------------------------------------------- */
<template>
  <div class="sz-verify-code">
    <!-- 图形验证码输入框 -->
    <transition name="fade">
      <div
        v-show="isShowGraphCode"
        class="sz-mask sz-animated"
        @click.prevent="isShowGraphCode = !isShowGraphCode"
      ></div>
    </transition>
    <transition name="zoom">
      <div v-show="isShowGraphCode" class="content sz-animated">
        <!-- 顶部关闭按钮 -->
        <div class="title">操作频繁</div>
        <div class="sub-title">请输入图形验证码后继续</div>
        <!-- 用户输入框 -->
        <div class="code-content1">
          <input
            v-model.trim="sGraphCode"
            class="code-content1-code"
            placeholder="输入图形验证码"
            maxlength="4"
            @blur="scrollBy"
          />
          <img
            :src="sGraphCodeImg"
            class="code-content1-img"
            height="100%"
            alt=""
            @click="ajaxGetGraphic"
          />
        </div>
        <div class="tip">
          <span>点击图片可重新获取</span>
        </div>
      </div>
    </transition>
  </div>
</template>

/* -------------------------------------------- script
-------------------------------------------- */
<script>
import xss from "xss"; // xss过滤
export default {
  name: "szVerifyCode",
  components: {},

  data() {
    return {
      isSending: false, // 是否发送中
      isShowGraphCode: false, // 是否展示图形验证码
      isCanSendVerifyCode: true, // 是否允许发送短信验证码
      oTimer: null, // 定时器对象
      nDelayTime: 60, // 发送短信后等待时间，持续刷新
      sMobile: "", // 用户手机号
      sGraphCodeImg: "", // base64转码后的图片
      sGraphCode: "", // 图形验证码
    };
  },

  /* 一.生命周期函数 */
  created() {
    this.emitStatus();
  },

  /* 二.监控函数 */
  watch: {
    sGraphCode(newVal) {
      if (newVal.length === 4) {
        this.ajaxSendVerifyCode();
      }
    },
  },

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用
    actionSendVerifyCode(mobile) {
      this.sMobile = mobile;
      this.checkMobile().then(() => {
        this.ajaxSendVerifyCode();
      });
    },
    // 刷新图形验证码
    actionRefreshGraphCode() {
      this.checkMobile().then(() => {
        this.ajaxGetGraphic();
      });
    },
    /* ----------------------内部功能函数------------------------ */
    // 初始化计时器
    initInterval() {
      this.nDelayTime = 60;
      this.isCanSendVerifyCode = false;
      clearInterval(this.oTimer);

      // 初始化读秒状态，解决弹弹窗出现时读秒延迟问题1
      this.emitStatus();
      this.oTimer = setInterval(() => {
        this.nDelayTime--;
        if (this.nDelayTime <= 0) {
          clearInterval(this.oTimer);

          // 读秒完成，允许继续操作
          this.isCanSendVerifyCode = true;
        }
        this.emitStatus();
      }, 1000);
    },
    // 重置状态，isCanSendVerifyCode设为true
    resetStatus() {
      clearInterval(this.oTimer);
      this.nDelayTime = 60;
      this.isCanSendVerifyCode = true;
    },
    // 校验手机号
    validateMobile(mobile) {
      return !!/^1[3456789]\d{9}$/.test(mobile);
    },
    checkMobile() {
      return new Promise((resolve, reject) => {
        if (!this.isCanSendVerifyCode) {
          return this.$toast(`${this.nDelayTime}s后再试`);
        } else if (this.sMobile === "") {
          return this.$toast("请先输入手机号");
        } else if (!this.validateMobile(this.sMobile)) {
          return this.$toast("手机号不正确");
        }
        resolve();
      });
    },
    emitStatus() {
      this.$emit("on-status", {
        isCanSendVerifyCode: this.isCanSendVerifyCode,
        nDelayTime: this.nDelayTime,
      });
    },
    // 处理微信兼容问题，IOS微信点开输入框后页面会被顶上去
    scrollBy() {
      window.scrollBy(0, 0);
    },
    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    // 获取短信验证码
    ajaxSendVerifyCode() {
      this.isSending = true;
      this.$api
        .post("/is/wap/logi/sendVerifycode", {
          mobile: xss(this.sMobile),
          graphCode: xss(this.sGraphCode),
          sendCodeType: "SZ_YOUXUAN",
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            // 需要将验证码框隐藏
            this.isShowGraphCode = false;
            this.$emit("on-success");
            this.initInterval();
          } else if (res.SZ_BODY && res.SZ_BODY.image) {
            this.isShowGraphCode = true;
            this.sGraphCodeImg = res.SZ_BODY.image;
            this.sGraphCode = "";
          } else {
            // 需要将验证码框隐藏
            this.isShowGraphCode = false;

            this.$toast(res.SZ_HEAD.RESP_MSG);
          }
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    // 获取图形验证码
    ajaxGetGraphic() {
      this.isSending = true;
      this.$api
        .get("/is/user/get_graphic", {
          mobile: xss(this.sMobile),
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            this.sGraphCodeImg = res.SZ_BODY.image;
          } else {
            this.$toast(res.SZ_HEAD.RESP_MSG);
          }
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

/* -------------------------------------------- style
-------------------------------------------- */
<style scoped lang="scss">
.sz-animated {
  transform: translateZ(0); // 强制开启IOS GPU加速
  // animation-duration: 2s;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
.sz-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.sz-verify-code {
  .content {
    position: fixed;
    top: 20%;
    left: 10%;
    z-index: 1000;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: rem(480);
    padding: rem(60) 0 rem(40);
    background: #fff;
    border-radius: rem(20);
    // 关闭按钮
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.35rem;
    }
    .title {
      color: #333;
      font-size: rem(44);
    }
    .sub-title {
      position: relative;
      top: rem(-30);
      color: #999;
      font-size: rem(32);
    }
    .code-content1 {
      position: relative;
      top: rem(-30);
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: rem(80);
      padding: 0 rem(70);
      &-code {
        width: rem(190);
        padding: 0 rem(20);
        border: 1px solid #eee;
        border-radius: rem(4);
      }
    }
    .tip {
      color: #b9b9b9;
      font-size: rem(32);
      &.pink {
        color: #ff6e6e;
      }
    }
  }
}
</style>
