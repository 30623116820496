import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    isVipReady: true, // 老逻辑，这里写死isVipReady为true
    hideHeader: ["alipay"], // 隐藏头部的ua.app
    hideMemberDialog: ["alipay"], // 隐藏会员弹窗的ua.app
    appointmentHideAddGoods: ["alipay"], // 预约时隐藏加项包ua.app
    isAgreeProto: false,
    isVip: false, // 是否为vip
    isLogin: false, // 是否登录
    maskMobile: "", // 手机号
    szMemberCode: "", // memberCode（埋点用）
  },
  mutations: {
    setState(state, data) {
      Object.keys(data).forEach((attr) => {
        if (typeof state[attr] === "undefined") {
          throw new Error(`[${attr} 未定义] global state 使用前需提前定义`);
        }
      });
      Object.assign(state, data);
    },
  },
  actions: {},
  getters: {
    // 获取 State 数据
    data(state, getters, rootState) {
      console.log(state, getters, rootState);
      return state;
    },
  },
};

export default GlobalModule;
