
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { throttle } from "@/module/tools";

@Component
export default class SzPage extends Vue {
  /* 外部传入属性 */
  /* 外部传入属性 */
  @Prop({ default: "" }) private title!: string; // 标题
  @Prop({ default: false }) private hasBack!: boolean; // 是否有返回按钮
  @Prop({ default: false }) private hasMenu!: boolean; // 是否有菜单按钮
  @Prop({ default: 0 }) private offset!: number; // // 滚动条与底部距离小于 offset 时触发 on-scroll-end 事件（滚动到底）

  /* 组件内部数据 */
  ua = ""; // 当前环境的user-agent
  hasPadding = false; // 是否加padding
  isToEnd = false; // 是否到底 如果到底后，一次到底只触发一次

  /* 一.生命周期函数 */
  created() {
    this.ua = navigator.userAgent;
  }

  mounted() {
    this.checkStatus();
  }

  /* 二.监控函数 watch computed */
  @Watch("ua")
  onMsgChanged(val: string, oldVal: string): void {
    console.log("[ua 发生变化]", val, oldVal);
  }

  // 如果是在 Safari， 需要底部加一个 Padding，防止底部被遮挡
  get isInSafari() {
    const ua = this.ua;
    if (ua.indexOf("iPhone") > -1 && ua.indexOf("Safari") > -1) {
      return true;
    } else {
      return false;
    }
  }

  get getPageStyle() {
    const oStyle: any = {};

    return oStyle;
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  actionScroll = throttle((ev: any) => {
    this.$emit("on-scroll", ev);

    const element = ev.srcElement;

    const scrollHeight = element.scrollHeight; // 滚动条总长度
    const offsetHeight = element.offsetHeight; // 容器高度
    const scrollTop = element.scrollTop; // 已滚动距离

    // Math.ceil 用于兼容 JS 计算误差
    if (Math.ceil(offsetHeight + scrollTop) >= scrollHeight - this.offset) {
      // 这里的 isToEnd 是当 offset 存在时，防止触底后 on-scroll-end 多次触发
      if (!this.isToEnd) {
        this.isToEnd = true;
        this.$emit("on-scroll-end");
      }
    } else if (this.isToEnd) {
      this.isToEnd = false;
    }
  }, 50);
  // 点击返回按钮（左上角图标）
  actionBack() {
    this.$emit("on-back");
    if (this.$router) {
      this.$router.back();
    } else {
      history.back();
    }
  }
  // 点击菜单按钮（右上角图标）
  actionMenu() {
    this.$emit("on-menu");
  }
  // 校验状态，这里主要看是初始化后是否滚动到底部
  checkStatus() {
    const oPageBody: any = this.$refs.pageBody;
    if (oPageBody.offsetHeight >= oPageBody.scrollHeight - this.offset) {
      // 说明尚未超出一屏，不包含滚动条，直接返回 on-scroll-end
      this.$emit("on-scroll-end");
    }
    console.log("[pageBody]", oPageBody.scrollHeight);
  }
  // 滚动到某个位置， 为数值是表示滚动到具体位置， 为字符串时表示滚动到预置位置 bottom 滚动到底部
  scrollTo(offset: number | string) {
    const oPageBody: any = this.$refs.pageBody;
    switch (offset) {
      case "bottom":
        offset = oPageBody.scrollHeight + oPageBody.offsetHeight;
        break;
    }

    // 滚动到某个位置
    oPageBody.scrollTop = offset;
  }
  /* ----------------------内部功能函数------------------------ */
  /* ----------------------服务请求函数------------------------ */
}
