/*
 * @Author: 王浩
 * @Date: 2021-07-08 10:05:55
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-07-08 10:07:43
 * 前端异常监控
 * 默认会在 vue.config.js 中插入 JS 文件
 */
import Vue, { PluginObject } from "vue";
import { loadScript } from "../tools";

const SentryPlugin: PluginObject<Vue> = {
  install(Vue) {
    // 添加前端异常监控
    if (process.env.VUE_APP_SENTRY_DSN) {
      if (Sentry) {
        this.initSentry(Vue);
      } else {
        // 做一个兜底，防止 vue.config.js 中加载 Sentry 异常
        Promise.all([
          loadScript(
            "https://static.shanzhen.com/js/sentry-vue@6.8.0/bundle.vue.js"
          ),
          loadScript(
            "https://static.shanzhen.com/js/sentry-tracing@6.8.0/bundle.tracing.js"
          ),
        ]).then(() => {
          this.initSentry(Vue);
        });
      }
    }
  },

  // 初始化 Sentry
  initSentry(Vue: Vue) {
    Sentry.init({
      Vue,

      // 输出错误日志到控制台
      logErrors: process.env.VUE_APP_DEBUG === "1",

      attachProps: true,
      dsn: process.env.VUE_APP_SENTRY_DSN,

      // 环境变量，用于区分用户在什么环境
      environment: process.env.VUE_APP_SENTRY_ENV,

      // 性能跟踪
      integrations: [new Sentry.Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: process.env.NODE_ENV === "production" ? 1 : 0.05,

      // 性能跟踪选项
      tracingOptions: {
        // 跟踪组件性能 Vue
        trackComponents: true,
      },
    });
  },
};

export default SentryPlugin;
