/*
 * @Author: 王浩
 * @Date: 2021-06-18 15:15:26
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-07-07 17:12:19
 * 测试用中间件
 */
import { Route } from "vue-router";

const middleware = {
  mode: "interval", // once (仅页面初始化时执行一次) | interval (循环执行)
  fn(to: Route, from: Route): Promise<void> {
    return new Promise((resolve) => {
      console.log("[middleware1 延时执行，仅执行一次]", to, from);
      document.title = to.meta?.title || "　";
      resolve();
    });
  },
};

export default middleware;
