const HOMEPAGE = () => import("@/views/custom_v4/homepage.vue");
const QUESTIONINTRO = () => import("@/views/custom_v4/question/intro.vue");
const QUESTION = () => import("@/views/custom_v4/question/question.vue");
const ASSESSMENT = () => import("@/views/custom_v4/assessment.vue");
const SELECTION = () => import("@/views/custom_v4/selection.vue");
const BASEITEMS = () => import("@/views/custom_v4/baseItems.vue"); // 基础检查项页

const ITEMS = () => import("@/views/custom_v4/items.vue");
const RESULT = () => import("@/views/custom_v4/result.vue");
const DRAINAGE = () => import("@/views/custom_v4/drainage.vue");

const ITEMS_RECOMMEND_BASE = () =>
  import("@/views/custom_v4/recommend/base.vue");
const ITEMS_RECOMMEND_MORE = () =>
  import("@/views/custom_v4/recommend/more.vue");

const basePath = "/custom_v4";
export default [
  // 首页、落地页
  {
    path: basePath + "/homepage",
    name: "customv4_homepage",
    component: HOMEPAGE,
    meta: {
      title: "善太医体检",
    },
  },
  // 问卷引导页
  {
    path: basePath + "/question_intro",
    name: "customv4_question_intro",
    component: QUESTIONINTRO,
    meta: {
      title: "善太医体检",
    },
  },
  // 问卷页
  {
    path: basePath + "/question",
    name: "customv4_question",
    component: QUESTION,
    meta: {
      title: "善太医体检",
    },
  },
  // 健康评估
  {
    path: basePath + "/assessment",
    name: "customv4_assessment",
    component: ASSESSMENT,
    meta: {
      title: "健康评估",
    },
  },
  // 选医院或机构
  {
    path: basePath + "/selection",
    name: "customv4_selection",
    component: SELECTION,
    meta: {
      title: "选医院或机构",
    },
  },
  // 选择体检项目
  {
    path: basePath + "/base_items",
    name: "customv4_base_items",
    component: BASEITEMS,
    meta: {
      title: "确认体检项",
    },
  },
  // 选择体检项目
  {
    path: basePath + "/items",
    name: "customv4_items",
    component: ITEMS,
    meta: {
      title: "确认体检项",
    },
  },
  // 支付成功
  {
    path: basePath + "/result",
    name: "customv4_result",
    component: RESULT,
    meta: {
      title: "支付",
    },
  },
  // 历史方案
  {
    path: basePath + "/drainage",
    name: "customv4_drainage",
    component: DRAINAGE,
    meta: {
      title: "结果",
    },
  },
  // 选择体检项目--推荐方案--基础套餐
  {
    path: basePath + "/items_recommend_base",
    name: "customv4_items_recommend_base",
    component: ITEMS_RECOMMEND_BASE,
    meta: {
      title: "确认体检项",
    },
  },
  // 选择体检项目--推荐方案--加项
  {
    path: basePath + "/items_recommend_more",
    name: "customv4_items_recommend_more",
    component: ITEMS_RECOMMEND_MORE,
    meta: {
      title: " ",
    },
  },
];
