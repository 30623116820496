/*
 * @Description: 在此输入描述
 * @Date: 2019-09-28 17:03:42
 * @Author: 郑道杨
 * @LastEditors: zhengdaoyang
 * @LastEditTime: 2021-04-08 09:52:02
 */

import Store from "@/store";
import Vue from "vue";
import md5 from "js-md5";
import { getCookie } from "./tools";

// 检测返回值
export function checkRes(json) {
  return new Promise((resolve) => {
    if (json.SZ_HEAD.RESP_CODE === "S0000") {
      // 请求成功
      resolve({
        data: json.SZ_BODY,
      });
    } else {
      resolve({
        data: "",
        error: json.SZ_HEAD.RESP_CODE,
        msg: json.SZ_HEAD.RESP_MSG,
      });
    }
  });
}

// 获取cookie、
export function getCookie1(name) {
  var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  var arr = document.cookie.match(reg);
  if (arr) {
    return arr[2];
  } else {
    return null;
  }
}

// 设置cookie,增加到vue实例方便全局调用
export function setCookie(cName, value, expireTimes) {
  var nowdate = new Date();
  var exdate = new Date(nowdate.getTime() + expireTimes);
  document.cookie =
    cName +
    "=" +
    escape(value) +
    (expireTimes == null ? "" : ";expires=" + exdate.toGMTString()) +
    "; path=/";
}

// 删除cookie
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie1(name);
  if (cval != null) {
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  }
}

// 判断是否为微信
export function isWeixin() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (
    userAgent.indexOf("MicroMessenger") >= 0 &&
    userAgent.indexOf("wxwork") < 0
  ) {
    return true;
  }
  return false;
}

// 判断是否在微信小程序内,（ 仅支持微信7.0 之后）
export function isMiniProgram() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.indexOf("miniprogram") >= 0) {
    return true;
  } else {
    return false;
  }
}

// 判断是否为APP
export function isMyApp() {
  if (window.location.href.indexOf("h5.app") >= 0) {
    return true;
  } else if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
  return false;
}

// 获取业务类型
export function getBizType() {
  return "新主站善太医";
}

export function getPlatformType() {
  const { app, os } = getUa1();

  let platformType = "";
  const isMobile = os === "android" || os === "ios";
  const isAlipay = app === "alipay";
  const isWx = app === "wx";

  if (isAlipay) {
    platformType = "ali";
  } else if (isWx) {
    platformType = "wechat";
  } else if (isMobile) {
    platformType = "mobile";
  } else {
    platformType = "pc";
  }

  return platformType;
}

/**
 * 检测是否为移动端
 */
export function isMobile() {
  if (/http(s?):\/\/m/.test(location.origin)) {
    // 正常判断方法
    return true;
  } else if (location.href.indexOf("wap") > -1) {
    // 兼容开发环境
    return true;
  } else if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
}

// 判断是否为善太医app
export function isSZApp() {
  if (navigator.userAgent.indexOf("com.wayyue.shanzhen") > -1) {
    return true;
  } else {
    return false;
  }
}

export function urlEncode(param, encode) {
  if (param === null) return "";
  var paramArr = [];
  for (const key in param) {
    paramArr.push(
      `${key}=${
        encode === null || encode ? encodeURIComponent(param[key]) : param[key]
      }`
    );
  }
  return paramArr.join("&");
}

export function scrollTo(element, to = 0) {
  let timer = null;
  let start;

  const scroll = function () {
    if (start && element.scrollTop > start) {
      clearInterval(timer);
      document.removeEventListener("scroll", scroll);
    }
  };
  document.addEventListener("scroll", scroll);

  timer = setInterval(() => {
    start = element.scrollTop;
    const speed = Math.ceil(start / 7);
    const target = element.scrollTop - speed;

    if (element.scrollTop <= to) {
      element.scrollTop = to;
      clearInterval(timer);
    } else {
      element.scrollTop = target;
    }
  }, 10);
}

// 运动
export const moveTo = (() => {
  let timer = null;
  return function (el, to) {
    console.log("一进入", to);
    let start;

    const preventDefault = function (e) {
      e.preventDefault();
    };

    // 初始化，重置上一次的内容
    clearInterval(timer);
    el.removeEventListener("touchstart", preventDefault);
    el.addEventListener("touchstart", preventDefault, false);
    timer = setInterval(() => {
      start = el.scrollTop;
      const speed = Math.ceil(Math.abs(start - to) / 6);
      if (el.scrollTop < to) {
        // 往下走
        el.scrollTop += speed;
      } else if (el.scrollTop > to) {
        // 往上走
        el.scrollTop -= speed;
      } else {
        el.scrollTop = to;
        el.removeEventListener("touchstart", preventDefault);
        clearInterval(timer);
      }
    }, 15);
  };
})();

// 获取平台,该方法主要用于后端接口
export function getSzpl() {
  const { app } = getUa1();

  const isAlipay = app === "alipay";
  const isWx = app === "wx";

  if (isAlipay) {
    return process.env.VUE_APP_ALPAY_SZPL;
  } else if (isWx) {
    return process.env.VUE_APP_WX_SZPL;
  } else {
    return "browser";
  }
}

// 判断是否为合格手机号
export function checkoutMobile(mobile) {
  return !!/^1[3456789]\d{9}$/.test(mobile);
}

// 判断是否为合格验证码
export function checkoutVerifyCode(code) {
  return !!/^\d{6}$/.test(code);
}

const cacheScript1 = {};
// 加载脚本
export function loadScript1(url) {
  // 如果已经加载过相同脚本，则不再加载
  if (cacheScript1[encodeURIComponent(url)])
    return cacheScript1[encodeURIComponent(url)];

  // 新加载脚本缓存中记录
  cacheScript1[encodeURIComponent(url)] = new Promise((resolve, reject) => {
    const oDom = document.createElement("script");
    oDom.onload = function () {
      resolve();
    };

    oDom.onerror = function () {
      reject(new Error());
    };
    oDom.src = url;
    document.querySelector("head").appendChild(oDom);
  });

  return cacheScript1[encodeURIComponent(url)];
}

// 设置网站导航的小Icon
export function setIcon(imgUrl) {
  var icon = document.createElement("link");
  icon.type = "image/x-icon";
  icon.rel = "shortcut icon";
  icon.href = imgUrl;
  document.getElementsByTagName("head")[0].appendChild(icon);
}

/**
 * 获取userAgent
 */
export function getUa1() {
  const data = {};
  const u = navigator.userAgent;

  // 判断OS
  if (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) {
    data.os = "android";
  } else if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    data.os = "ios";
  } else {
    data.os = "";
  }

  // 判断用户app
  if (u.match(/Alipay/i)) {
    data.app = "alipay";
  } else if (u.indexOf("MicroMessenger") >= 0 && u.indexOf("wxwork") < 0) {
    data.app = "wx";
  } else {
    data.app = "";
  }
  console.log("ua", u, data);
  return data;
}

/**
 * 自动登录，支持以下方式:
 *  支付宝
 * @result {Object} 调用登录的参数
 */
export function getAuthCode() {
  // 获取userAgent，判断需要走什么分支
  const ua = getUa1();
  return new Promise((resolve, reject) => {
    console.log("--------开始获取authCode--------");
    // 如果服务器注明已登录
    if (getCookie1("islogin") === "1") {
      reject(new Error("已登录，不需要再次登录"));
      return;
    }

    /**
     * 1、获取授权code
     * 2、加载对应的SDK，加载结束后(不管成功失败)resolve
     */
    console.log("--------开始判断ua.app-------");
    const usedStr = localStorage.getItem("usedCodeArray");
    let usedArr = usedStr ? usedStr.split(",") : [];
    switch (ua.app) {
      case "alipay":
        // eslint-disable-next-line
        const auth_code = getQueryString1('auth_code') // 由于在APP.vue中$route尚未初始化完成，所以拿不到query
        // eslint-disable-next-line
        if (!auth_code || usedArr.includes(auth_code)) {
          const arr = location.href.split("?");
          let norUrl = "";
          if (arr[1]) {
            norUrl = `${arr[0]}?${arr[1]
              .split("&")
              .filter((item) => item.split("=")[0] !== "code")
              .join("&")}`;
          } else {
            norUrl = `${arr[0]}`;
          }
          /* eslint-disable */
          location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${process.env.VUE_APP_ALPAY_APPID}&scope=auth_user&redirect_uri=${encodeURIComponent(norUrl)}`
        } else {
          usedArr.unshift(auth_code)
          if (usedArr.length > 5) {
            usedArr = usedArr.splice(0, 5)
          }
          localStorage.setItem('usedCodeArray', usedArr)
          loadScript1('https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js').finally(() => {
            resolve({
              method: 'alipayLogin',
              data: {
                mp: process.env.VUE_APP_ALPAY_MP,
                authCode: auth_code,
                authScope: 'AUTH_USER' // AUTH_BASE
              }
            })
          })
        }
        break
      case 'wx':
        const code = getQueryString1('code')
        console.log('code:' + code)
        // 设置usedArray里面都是已经使用的
        // snsapi_base
        // snsapi_userinfo
        if (!code || usedArr.includes(code)) {
          let arr = location.href.split('?')
          let norUrl = ''
          if (arr[1]) {
            norUrl = `${arr[0]}?${arr[1].split('&').filter(item => item.split('=')[0] !== 'code').join('&')}`
          } else {
            norUrl = `${arr[0]}`
          }
          console.log('------无code---------')
          location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WX_APPID}&redirect_uri=${encodeURIComponent(norUrl)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
        } else {
          console.log('------有code && 未使用---------')
          usedArr.unshift(code)
          if (usedArr.length > 5) {
            usedArr = usedArr.splice(0, 5)
          }
          localStorage.setItem('usedCodeArray', usedArr)
          loadScript1('https://res.wx.qq.com/open/js/jweixin-1.6.0.js').finally(() => {
            resolve({
              method: 'wxLogin',
              data: {
                mp: process.env.VUE_APP_WX_MP,
                authCode: code,
                authScope: 'SNSAPI_USERINFO' // SNSAPI_BASE
              }
            })
          })
        }
        break
      default:
        reject(new Error('当前应用无法获取授权码'))
        break
    }
  })
}

// 设置登录状态
export function setLoginStatus(vm, LOGIN_D, backUrl = false) {
  return new Promise((resolve, reject) => {
    try {
      vm.$store.global.set('isLogin', true)
      localStorage.setItem('isLogin', '1')
      if (LOGIN_D.maskMobile) { // 绑定了手机号
        console.log('绑定了手机号')
        vm.$store.global.set('maskMobile', LOGIN_D.maskMobile)
        localStorage.setItem('maskMobile', LOGIN_D.maskMobile)
        vm.$sa.login(LOGIN_D.szMemberCode)
      } else if (backUrl) { // 未绑定手机号，并且配置了返回url，说明需要登录
        vm.$store.global.set('maskMobile', '')
        localStorage.setItem('maskMobile', '')
        setCookie('maskMobile', '')
        vm.$router.push({
          name: 'login',
          query: {
            backUrl
          }
        })
        return
      } else {
        vm.$store.global.set('maskMobile', '')
        localStorage.setItem('maskMobile', '')
        setCookie('maskMobile', '')
      }
      console.log('set成功')
      resolve()
    } catch (e) {
      vm.$store.global.set('isLogin', false)
      vm.$store.global.set('maskMobile', '')
      reject(e)
    }
  })
}


/**
 * 获取url参数
 * @param {String} name 键
 */
export function getQueryString1(name) {
  let reg = `(^|&)${name}=([^&]*)(&|$)`
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

/**
 * 对象转查询字符串
 * @param {Object} obj 要转换的对象
 */
export function queryStringfy(obj, hasSpace = true) {
  let keys = Object.keys(obj);
  let str = '';

  keys.forEach(k => {
    if (!obj[k] && !(obj[k] === '')) return
    if (!obj[k] && !hasSpace) return
    str += `&${k}=${obj[k]}`;
  });

  return str.slice(1);
}

// 初始化state管理器（主要是为了防止刷新页面状态丢失）
export function initStoreState() {
  Store.global.set('isLogin', getCookie1('islogin') === '1')
  const maskMobile = getCookie1('maskMobile') || localStorage.getItem('maskMobile')
  if (!maskMobile) {
    Store.global.set('maskMobile', '')
  } else {
    Store.global.set('maskMobile', maskMobile)
  }
  Store.global.set('szMemberCode', localStorage.getItem('szMemberCode'))
}

// 初始化善太医健康无保险
export function initInsuranceState() {
  const noInsurance = getQueryString1('noInsurance') || sessionStorage.getItem('noInsurance')
  switch (noInsurance) {
    case '1':
      Store.global.set('noInsurance', true)
      break
    default:
      Store.global.set('noInsurance', false)
      break
  }
}

// 初始善太医健康社

export function initClinicState() {
  const isClinic = getQueryString1('isClinic') || sessionStorage.getItem('isClinic')
  switch (isClinic) {
    case '1':
      Store.global.set('isClinic', true)
      break
    default:
      Store.global.set('isClinic', false)
      break
  }
}

// 返回错误结果
export function createErrorRes() {
  return {
    data: {
      SZ_BODY: {

      },
      SZ_HEAD: {
        RESP_CODE: "S0001",
        RESP_MSG: "已登录，或者正在登录"
      }
    }
  }
}

// 获取随机字符串
export function getNonceStr(len = 16) {
  const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let nonceStr = '';
  for (let i = 0; i < len; i++) {
    nonceStr += str.charAt(Math.floor((Math.random() * str.length)));
  }
  return nonceStr;
}

// 设置couponId
export function setCouponId(couponId) {
  if (couponId) {
    // 用过的couponId
    let couponIdArr = JSON.parse(localStorage.getItem('custom_v4_used_coupon_ids') || '[]')
    couponIdArr.push(couponId)
    localStorage.setItem('custom_v4_used_coupon_ids', JSON.stringify(couponIdArr))
  }
}

// couponId是否用过
export function hasCouponId(couponId) {
  couponId = parseInt(couponId)
  let couponIdArr = JSON.parse(localStorage.getItem('custom_v4_used_coupon_ids') || '[]')
  return couponIdArr.includes(couponId)
}

// 同步 session 到活动框架项目，并调整
export async function syncSessionToCooperation(redirectUrl, nonceStr) {
  if(!redirectUrl) {
    Vue.prototype.$dialog.alert({ message: '请填写redirectUrl' })
  }
  console.error(redirectUrl)
  // nonceStr 如果从外面传递，就取外面的
  const user = nonceStr || getNonceStr(32)
  await Vue.prototype.$api.get('/is/wap/pay/pre_query_new', { user })

  let url = redirectUrl
  if(url.includes('?')) {
    url = `${url}&user=${user}&isFromSty=1`
  } else {
    url = `${url}?user=${user}&isFromSty=1`
  }
  location.href = url
}

// 根据szUserId进行50%的abtest，不会因为浏览器缓存消失导致重新分配abtest
export function abTestByUserId() {
  const id = getCookie1('szUserId')
  if (!id) throw new Error('获取szUserId失败')
  // 获取加密后的最后一位字符，如果是字母转数字
  const arr = md5(id).split('')
  const lastLetter = arr[arr.length - 1]
  const lastNumber = isNaN(parseInt(lastLetter)) ? lastLetter.toUpperCase().charCodeAt() - 64 : parseInt(lastLetter)
  return lastNumber % 2 === 0
}

// 回退
export function closeWebview () {
  if (window.WeixinJSBridge) {// 微信
    window.WeixinJSBridge.call('closeWindow') 
  } else {
    window.history.back()
  }
}

export async function calcRealActivityPrice(goodsCode, activityPriceStr, saleOriginPriceStr) {
  // 1、判断登录
  if(getCookie('islogin') !== '1'){
    console.error("未登录");
    return activityPriceStr //未登录且返回默认活动价字符串（按商品code固定展示-300和-200）
  }

  // 2、判断券
  const result = await Vue.prototype.$api.slget('mkt_canUseCoupon', { couponType: 'MEMBER_QUERY_COUPON_LIST', goodsCode })
  if(!result.SZ_BODY.COUPON_COUNT) {
    return saleOriginPriceStr //若没有券则返回原价字符串
  }

  // 3、组装mkt_discountInfo的请求参数
  const couponId = result.SZ_BODY.COUPON_LIST[0].id
  const params = {
    goodsInfoStr:JSON.stringify([{ goodsCode,"addGoodsList":[], couponId } ]),
    orderFrom: 118,
    isVip: Store.global.state.isVip ? '1' : '0' // 状态数据
  }

  // 4、请求mkt_discountInfo
  const {
    SZ_BODY:{ payAmount } 
  } = await Vue.prototype.$api.slget('mkt_discountInfo', params)
  
  // 5、返回 payAmount / 1000
  return String( payAmount / 1000) //若有券则计算券后活动价字符串并返回
}

