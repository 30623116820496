/*
 * File: pay-pre-data.js
 * Project: sz-html-tpl-vue
 * File Created: Wednesday, 20th March 2019 3:15:46 pm
 * Author: 王浩
 * -----
 * Last Modified: Wednesday, 20th March 2019 3:16:04 pm
 * Modified By: 王浩
 * -----
 * Desc: 同步支付前置数据中间件
 *    这里主要是支付的业务中会包含从微信跳转到浏览器中支付，所以需要同步状态，这里添加的参数主要是用来做同步登录态的
 */
import { Route } from "vue-router";
import router from "vue-router";
import Vue from "vue";
import {
  queryStringfy,
  getQueryString,
  isWechat,
  getNonceStr,
  getSzpl,
} from "@/module/tools";

const middleware = {
  mode: "interval", // once | interval
  fn(to: Route): Promise<void> {
    // 首次进入一定会调取登录状态的
    // 未登录重新登录，更新cookie
    // 已登录不再调取
    console.error("===to.path====");
    console.error(to.path);
    console.error(to.query);
    return new Promise((resolve, reject) => {
      // 32 长度是首页登录用的
      // 24 长度是支付绑定登录态用的
      const user = getNonceStr(24);
      // 微信内，并且是支付页，执行路由前置操作（加上免登录验签）
      if (isWechat() && ["cashier", "pay"].includes(to.path.split("/")[1])) {
        if (to.query.user === undefined) {
          Vue.prototype.$api.get("/is/wap/pay/pre_query_new", { user });
          to.query._t = Date.now().toString();
          to.query.user = user;
          to.query.szpl = getQueryString("szpl") || getSzpl();
          const payCallBackUrl = getQueryString("payCallBackUrl");
          if (payCallBackUrl) {
            to.query.payCallBackUrl = encodeURIComponent(payCallBackUrl);
          }
          console.log(`${location.pathname}?${queryStringfy(to.query)}`);
          setTimeout(() => {
            location.replace(`${location.pathname}?${queryStringfy(to.query)}`);
          }, 200);
          return;
        }
      } else {
        console.log("不是支付页，或者不在微信内");
      }
      resolve();
    });
  },
};

export default middleware;
