import Vue, { PluginObject } from "vue";
import { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { getUa, getQueryString, getCookie, isFormData } from "@/module/tools";
import API from "@/module/API";

const ApiPlugin: PluginObject<Vue> = {
  /**
   * 这部分接口不在ajax方法中拦截，并且不跳转到个人中心页，
   * 将登录态失败相关的信息返回到页面，让页面自行处理
   */
  noIntercept: [
    "/is/user/wx_login", // 微信登录
    "/is/user/alipay_login", // 支付宝登录
    "/is/wap/query/vipUserInfo", // 查询vip
    "/is/wap/service/serviceOrderQuery", // 查询是否购买了 家庭医生/1元定制 订单
    "/is/wap/query/serviceExamOrderDetail", // 服务型体检套餐详情
  ],
  install(Vue) {
    this.instance = new API({
      baseURL: process.env.VUE_APP_API_URL as string, // API 地址
    });

    // 添加拦截器
    this.useRequestInterceptor();
    this.useResponseInterceptor();

    // 绑定 API
    Vue.prototype.$api = this.instance;
  },

  /**
   * 添加请求拦截器
   */
  useRequestInterceptor() {
    this.instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // 在发送请求之前做些什么
        // 发送请求前添加额外参数
        const otherData: any = {};

        const szpl = this.getSzpl();
        const platformName =
          getQueryString("platformName") || process.env.VUE_APP_PLATFORM_NAME;
        if (szpl) {
          otherData.szpl = szpl;
        }
        if (platformName) {
          otherData.platformName = platformName;
        }

        switch (config.method?.toLowerCase()) {
          case "post":
            if (isFormData(config.data)) {
              // 这里表示是 FormData 格式的数据，通常是用于文件上传
              Object.keys(otherData).forEach((attr) => {
                config.data.append(attr, otherData[attr]);
              });
              config.data.append("_csrf", getCookie("csrfToken"));
            } else {
              // 其它情况我们都假定数据为 JS 对象
              config.data = Object.assign(
                {
                  ...otherData,
                  _csrf: getCookie("csrfToken"),
                },
                config.data
              );
            }
            break;
          case "get":
          default:
            // 无值时默认为 get 请求
            config.params = Object.assign(otherData, config.params);
            break;
        }
        return config;
      },
      (error: AxiosError) => {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );
  },

  /**
   * 添加响应拦截器
   */
  useResponseInterceptor() {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        // 请勿删除， 生产需输出，用于排错
        console.log(`[接口调用]${response.config.url}`, response.data);

        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        if (
          ["B0201", "B0205", "SE3006", "02000401", "01000501"].includes(
            response.data.SZ_HEAD.RESP_CODE
          ) &&
          !this.noIntercept.includes(response.config.url)
        ) {
          try {
            if (getCookie("islogin") === "1") {
              vant.Toast("登录过期，请重新登录");
            } else {
              vant.Toast("请先登录");
            }

            Vue.prototype.$toLogin();

            // if (process.env.NODE_ENV === "development") {
            //   location.href =
            //     process.env.VUE_APP_LOGIN_HOST +
            //     `/wap/login/login?backUrl=${encodeURIComponent(location.href)}`;
            // } else {
            //   location.href = `/wap/login/login?backUrl=${encodeURIComponent(
            //     location.href
            //   )}`;
            // }

            return response.data;
          } catch (e) {
            console.log(e);
          }
        }

        if (response.data.SZ_HEAD.RESP_CODE === "S0000") {
          // 必须在接口成功响应时添加 canCache 字段
          // 以防止在接口异常时仍然缓存接口数据
          response.data.canCache = true;
        }

        return response.data;
      },
      (error: AxiosError) => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error);
      }
    );
  },

  /**
   * 获取善诊平台
   */
  getSzpl() {
    const uaInfo = getUa();

    let szpl = getQueryString("szpl");

    // 如果链接上取到了 szpl 以链接上的为准
    if (szpl) return szpl;

    switch (uaInfo.platform) {
      case "alipay":
        szpl = process.env.VUE_APP_ALPAY_SZPL;
        break;
      case "wechat":
        szpl = process.env.VUE_APP_WX_SZPL;
        break;
      default:
        szpl = "browser";
        break;
    }

    return szpl;
  },
};

export default ApiPlugin;
