/* * File: szIcon.vue * Project: sz-html-fa-vue * File Created: Tuesday, 3rd
July 2018 3:12:44 pm * Author: 王浩 * ----- * Last Modified: Wednesday, 11th
July 2018 4:44:26 pm * Modified By: 王浩 * ----- * Desc: 图标组件 */ /*
eslint-disable */
<template>
  <svg :style="getStyle" class="sz-icon" aria-hidden="true" @click="click">
    <use :xlink:href="`#icon-${name}`"></use>
  </svg>
</template>

<script>
export default {
  name: "szIcon",
  data() {
    return {};
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
  computed: {
    getStyle() {
      let str = "";
      if (this.color) str += `color:${this.color};`;
      if (this.size) str += `font-size:${this.size / 50}rem;`;
      return str;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sz-icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentColor;
}
</style>
