import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MiddleWare from "./middleware";
import Routes from "./routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [{ path: "/", redirect: { name: "home" } }];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  base: process.env.VUE_APP_ROUTER_BASE,
  routes: [...routes, ...Routes],
});

MiddleWare.use(router);

export default router;
