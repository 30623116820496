import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    recommendDetail: {},
    recommendBaseData: [], // 推荐方案基础项数据
    recommendMoreData: {}, // 推荐方案加项数据
    isSkipSelection: false, // 问卷流程是否跳过选择机构页
  },
  getters: {
    recommendDetail: (state) => state.recommendDetail,
    recommendBaseData: (state) => state.recommendBaseData,
    recommendMoreData: (state) => state.recommendMoreData,
    isSkipSelection: (state) => state.isSkipSelection,
  },
  mutations: {
    setRecommendDetail(state, layout) {
      state.recommendDetail = layout;
    },
    setRecommendBaseData(state, layout) {
      state.recommendBaseData = layout;
    },
    setRecommendMoreData(state, layout) {
      state.recommendMoreData = layout;
    },
    setSkipSelectionFlag(state, layout) {
      state.isSkipSelection = layout;
    },
  },
  actions: {},
};

export default GlobalModule;
