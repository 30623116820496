import { Module } from "vuex";
import { RootState } from "@/store/types";
import { State } from "vuex-class";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    selectedTag: {
      currentTagList: [],
      isRecommendTag: true,
      peopleType: 1,
      relatedAge: "old",
      otherSelectedTag: {
        currentSeries: "", // 当前系列
        currentInstitType: "", // 当前机构类型
        currentGender: "", // 当前性别
        currentPrice: "", // 当前价格
        currentLowPrice: "",
        currentHighPrice: "",
      },
    }, // 筛选标签
    currentTab: "suit", // 首页tab栏缓存
    choseLocationData: {
      regionCode: "",
      regionChnName: "全国",
    },
  },
  getters: {
    selectedTag: (state) => state.selectedTag,
    currentTab: (state) => state.currentTab,
    choseLocationData: (state) => state.choseLocationData,
  },
  mutations: {
    setSelectedTag(state, layout) {
      state.selectedTag = layout;
    },
    setCurrentTab(state, layout) {
      state.currentTab = layout;
    },
    setChoseLocationData(state, layout) {
      state.choseLocationData = layout;
    },
  },
  actions: {},
};

export default GlobalModule;
