import Vue from "vue";

import szPage from "@/components/biz/szPage/index.vue";
import szIcon from "@/components/biz/szIcon/index.vue";
import szProtocol from "@/components/biz/szProtocol/index.vue";
import szVerifyCode from "@/components/biz/szVerifyCode/index.vue";
import szGraphCode from "@/components/biz/szGraphCode/index.vue";
import szFormItem from "@/components/biz/szFormItem/index.vue";
import szHeader from "@/components/biz/szHeader/index.vue";
import szRegion from "@/components/biz/szRegion/index.vue";
import szMDrag from "@/components/biz/szMDrag/index.vue";
import szInstit from "@/components/biz/szInstit/index.vue";
import szTag from "@/components/biz/szTag.vue";

Vue.component("szPage", szPage);
Vue.component("szIcon", szIcon);
Vue.component("szProtocol", szProtocol);
Vue.component("szVerifyCode", szVerifyCode);
Vue.component("szGraphCode", szGraphCode);
Vue.component("szFormItem", szFormItem);
Vue.component("szHeader", szHeader);
Vue.component("szRegion", szRegion);
Vue.component("szInstit", szInstit);
Vue.component("szMDrag", szMDrag);
Vue.component("szTag", szTag);
