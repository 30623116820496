const basePath = "/change_mobile";
export default [
  // 验证旧手机号
  {
    path: basePath + "/verify",
    name: "verifyOldMobile",
    component: () => import("@/views/changeMobile/verifyOldMobile"),
    meta: {
      title: "验证旧手机号",
    },
  },
  // 绑定新手机号
  {
    path: basePath + "/bind",
    name: "bindMobile",
    component: () => import("@/views/changeMobile/bind"),
    meta: {
      title: "更换手机号",
    },
  },
  // 换绑手机号成功
  {
    path: basePath + "/success",
    name: "changeMobileSuccess",
    component: () => import("@/views/changeMobile/success"),
    meta: {
      title: "换绑成功",
    },
  },
];
