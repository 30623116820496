import { Module } from "vuex";
import { RootState } from "@/store/types";

interface ReportState {
  [key: string]: unknown;
}
const reportModule: Module<ReportState, RootState> = {
  namespaced: true,
  state: {
    reportInfo: {},
    isPlayedAudio: false,
    hasReportMedia: false, // 是否有语音解读
  },
  mutations: {
    setReportInfo(state, reportInfo) {
      state.reportInfo = reportInfo;
    },
    setIsPlayedAudio(state, isPlayedAudio) {
      state.isPlayedAudio = isPlayedAudio;
    },
    setHasReportMedia(state, hasReportMedia) {
      state.hasReportMedia = hasReportMedia;
    },
  },
};
export default reportModule;
