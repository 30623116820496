/**
 * 预约相关页面
 */

const index = () =>
  import(/* webpackChunkName: "index" */ "@/views/appointment/index.vue");

export default [
  {
    path: "/appointment/index",
    name: "appointmentIndex",
    component: index,
    meta: {
      title: "买体检",
    },
  },
];
