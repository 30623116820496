import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    selectedItems: [], // 选择加项页已选项列表
    gender: "", // 性别
    institConfig: "", // 选择的机构信息
    institVoList: [], // 查得的所有机构的数组，用于所有加项页，用institCode和gender查后,保存的同一机构不同性别的goodsCode
    originLocationData: {}, // 根据定位获得的地理位置信息，用于商详页头部地点显示（善太医第2期）
    choosedSupply: "", // 善太医2期 选中的供给类型。'instit'、'public'
    ageStatus: "", // 善太医系列落地页新增，'old','young'
  },
  getters: {
    selectedItems: (state) => state.selectedItems,
    gender: (state) => state.gender,
    institConfig: (state) => state.institConfig,
    institVoList: (state) => state.institVoList,
    originLocationData: (state) => state.originLocationData,
    choosedSupply: (state) => state.choosedSupply,
    ageStatus: (state) => state.ageStatus,
  },
  mutations: {
    setSelectedItems(state, layout) {
      state.selectedItems = layout;
    },
    setGender(state, layout) {
      state.gender = layout;
    },
    setInstitConfig(state, layout) {
      state.institConfig = layout;
    },
    setInstitVoList(state, layout) {
      state.institVoList = layout;
    },
    setOriginLocationData(state, layout) {
      state.originLocationData = layout;
    },
    setChoosedSupply(state, layout) {
      state.choosedSupply = layout;
    },
    setAgeStatus(state, layout) {
      state.ageStatus = layout;
    },
  },
  actions: {},
};

export default GlobalModule;
