/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-12-02 20:47:47
 * 下单相关
 */
import { RouteConfig } from "vue-router";
const basePath = "/recommend";

const Routes: Array<RouteConfig> = [
  {
    path: basePath + "/index",
    name: "recommendIndex",
    component: () => import("@/views/recommend/index"),
    meta: {
      title: "善太医体检",
    },
  },
];

export default Routes;
