/*
 * @Author: 王浩
 * @Date: 2021-06-18 15:15:26
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-11-29 16:20:38
 * 测试用中间件
 */
import { Route } from "vue-router";
import Vue from "vue";
import { getCookie } from "@/module/tools"; // 导入工具包

const middleware = {
  mode: "interval", // once | interval
  fn(to: Route): Promise<void> {
    // 首次进入一定会调取登录状态的
    // 未登录重新登录，更新cookie
    // 已登录不再调取
    return new Promise((resolve) => {
      // 没有登录态则不需要判断
      if (getCookie("islogin") === null) {
        Vue.prototype.$api.get("/is/refreshLoginStatus").finally(() => {
          resolve();
        });
        return;
      }

      if (getCookie("islogin") === "0") return resolve();
      // 非 pay 页面才调用 user_check_login_state 接口
      // 统一兑换页也不调用 user_check_login_state 接口
      if (
        to.path.split("/")[2] !== "pay" &&
        !["commerceConversion", "home"].includes(to.name as string)
      ) {
        Vue.prototype.$api.slpost("user_check_login_state").finally(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  },
};

export default middleware;
