
import { Component, Watch, Prop, Mixins } from "vue-property-decorator";
import { getPosition, getDistance } from "@/module/tools";
import GetPositionMixins from "@/module/mixins/getPosition";
// interface RegionData {
//   municipalityRegion: boolean // 是否为直辖市
//   regionChnName: string // 地区名称
//   regionCityCapAlp: string // 地区首字母
//   regionCode: string // 地区编号
// }
// https://cn.vuejs.org/v2/guide/typescript.html
// https://github.com/vuejs/vue-class-component
@Component({
  model: {
    prop: "value",
    event: "change",
  },
  mixins: [GetPositionMixins],
  components: {
    Instit: () => import("./components/instit.vue"), // 机构卡片
    ChooseDate: () => import("./components/chooseDate.vue"), // 选择日期
  },
})
export default class Home extends Mixins(GetPositionMixins) {
  // Mixins 相关函数需要声明防止报错
  @Prop() private value!: boolean;
  @Prop({ default: false }) private useOldApi!: boolean;
  @Prop({ default: false }) private reserveQuery!: boolean; // 是否为预约时查询， 预约时字段为 true 后端有特殊逻辑
  @Prop() private goodsCode!: string; // 商品编号，有商品编号时，查询可约地区需要调用新接口
  @Prop() private orderCode!: string; // 订单编号 - 通过订单获取当前订单支持预约的地区
  @Prop() private addGoodsCodeListStr!: string; // 加项包编号
  @Prop() private activityCode!: string; // 活动编号 （和活动框架编号不是一个东西）
  @Prop() private gender!: string; // 用户性别
  @Prop() private regionData!: any; // 机构编号

  isCalledGetLocation = false; // 是否已调用过获取定位接口
  isInstitLoading = false; // 机构数据是否加载中
  isShowRegion = false; // 是否展示地区组件
  isShowChooseDate = false; // 是否展示选择日期
  selectRegionList: any = null; // 当前选中的地区列表
  longitude: string | number = ""; // 经度
  latitude: string | number = ""; // 纬度

  institList: Array<any> = []; // 机构列表
  currentRegionData = {
    // 当前定位的地区数据
    municipalityRegion: false,
    regionChnName: "",
    regionCode: "",
  };

  /* 一.生命周期函数 */
  created(): void {
    this.$sa.track("page_view", {
      page_name: "查询机构页",
      page_source: "体检预约页",
    });

    if (this.regionData) {
      console.error("有regionData");
      console.error(this.regionData);
      this.currentRegionData = this.regionData;
    } else {
      console.error("无regionData");
      console.error(this.regionData);
      this.mix_ajaxQueryDefaultCity().then((data) => {
        this.currentRegionData = data;
      });
    }
  }

  /* 二.监控函数 */
  @Watch("value")
  onMsgChanged(val: boolean): void {
    if (!val) {
      this.selectRegionList = null;
      this.institList = [];
    } else {
      let isAuthed = false;
      setTimeout(() => {
        if (isAuthed) {
          this.mix_ajaxQueryCityByLocationOnce()
            .then((data) => {
              this.selectRegionList = null;
              this.currentRegionData = data;
              this.initInstitData(this.currentRegionData);
            })
            .catch(() => {
              this.initInstitData(this.currentRegionData);
            });
        } else {
          this.initInstitData(this.currentRegionData);
        }
      }, 30);
      getPosition().then(() => {
        isAuthed = true;
      });
    }
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  actionUpdateInstitData(institData: any, index: number) {
    this.institList.splice(index, 1, institData);
  }
  // 展示预约日期组件
  actionShowReserveDay(item, description) {
    this.$sa.track("click", {
      page_name: "查询机构页点击",
      description,
    });
    console.log(item);
    const oVm: any = this.$refs.chooseDate;
    oVm.getReserveDate({
      goodsCode: this.goodsCode,
      institCode: item.institCode,
      institData: item,
      orderCode: this.orderCode,
      activityCode: this.activityCode,
      addGoodsCodeList: this.addGoodsCodeListStr,
      reserveQuery: this.reserveQuery,
    });
    this.isShowChooseDate = !this.isShowChooseDate;
  }

  // 弹窗关闭
  actionClosed() {
    this.$emit("change", false);
  }
  // 切换地区
  actionChangeRegion(regionList) {
    this.isShowRegion = false;
    this.selectRegionList = regionList;

    if (regionList.length > 0) {
      const d = regionList[regionList.length - 1];
      this.ajaxQueryInstit(d.regionCode);
    }
  }

  // 选择日期
  actionChangeDate({ date, institData }) {
    this.isShowChooseDate = false;

    this.$sa.track("click", {
      page_name: "查询机构页点击",
      description: "点击下一步",
    });

    this.$emit("on-complete", {
      date,
      institData,
    });
  }

  /* ----------------------内部功能函数------------------------ */
  // 初始化
  initInstitData({ regionCode, regionChnName, municipalityRegion }) {
    this.currentRegionData.municipalityRegion = municipalityRegion;
    this.currentRegionData.regionCode = regionCode;
    this.currentRegionData.regionChnName = regionChnName;
    this.ajaxQueryInstit(regionCode);
  }

  // 根据距离排序
  sortByDistance(institList) {
    return institList
      .map((item) => {
        const distance = getDistance(
          this.latitude,
          this.longitude,
          item.latitude,
          item.longitude
        );
        item.distance = distance;
        return item;
      })
      .sort((a, b) => {
        return a.distance - b.distance;
      });
  }

  /* ----------------------服务请求函数------------------------ */
  // 通过地区编号查询机构
  ajaxQueryInstit(regionCode) {
    if (this.isInstitLoading) return;
    this.isInstitLoading = true;
    this.$api
      .get("/is/wap/query/instits", {
        reserveQuery: this.reserveQuery,
        l2RegionCode: regionCode,
        goodsCode: this.goodsCode,
        activityCode: this.activityCode,
        addGoodsCodeList: this.addGoodsCodeListStr,
        gender: this.gender,
        orderCode: this.orderCode,
        isShowReserveDate: 0,
      })
      .then((res) => {
        if (res.SZ_HEAD.RESP_CODE === "S0000") {
          if (this.longitude) {
            // 需要排序
            this.institList = this.sortByDistance(res.SZ_BODY.INSTIT_D || []);
          } else {
            this.institList = res.SZ_BODY.INSTIT_D || [];
          }
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG);
        }
      })
      .finally(() => {
        this.isInstitLoading = false;
      });
  }

  // 查询默认城市
  ajaxQueryDefaultCity() {
    return this.$api
      .slget("cgi_commonDictQueryByKeyAndName", {
        key: "DEFAULT_CITY",
        name: "DEFAULT_CITY_NAME",
      })
      .then((res) => {
        if (res.SZ_HEAD.RESP_CODE === "S0000") {
          this.currentRegionData = JSON.parse(res.SZ_BODY.cacheDto.content);
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG);
        }
      });
  }
}
