/*
 * @Author: 王浩
 * @Date: 2021-06-24 15:22:40
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-07-14 20:06:09
 * 移动端调试工具
 */

import { PluginObject } from "vue";
import { loadScript, getQueryString } from "@/module/tools";
const ErudaPlugin: PluginObject<() => void> = {
  install(Vue, cb) {
    if (
      process.env.VUE_APP_DEBUG === "1" ||
      getQueryString("_szdebug") === "1"
    ) {
      // 仅在开发、测试环境打开工具
      Promise.all([
        loadScript("https://static.shanzhen.com/js/eruda@2.4.1/eruda.min.js"),
        loadScript(
          "https://static.shanzhen.com/js/eruda-dom@2.0.0/eruda-dom.js"
        ),
        loadScript(
          "https://static.shanzhen.com/js/eruda-features@2.0.0/eruda-features.js"
        ),
        loadScript(
          "https://static.shanzhen.com/js/eruda-timing@2.0.0/eruda-timing.js"
        ),
      ]).then(() => {
        eruda.init();
        eruda.add(erudaDom);
        eruda.add(erudaFeatures);
        eruda.add(erudaTiming);
        if (cb) {
          cb();
        }
        const snippets = eruda.get("snippets");
        snippets.clear();
        snippets.add(
          "清除indexedDB",
          () => {
            // @ts-ignore
            indexedDB.databases().then((dbList) => {
              dbList.forEach((db: any) => {
                indexedDB.deleteDatabase(db.name);
              });

              window.alert("删除成功");
              location.reload();
            });
          },
          "清空当前域名全部 indexedDB 数据"
        );
      });
    } else {
      if (cb) {
        cb();
      }
    }
  },
};

export default ErudaPlugin;
