/*
 * @Author: 王浩
 * @Date: 2021-06-24 15:22:40
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-11-29 14:52:30
 * AB 测试相关配置
 */
import Vue, { PluginObject } from "vue";
import szGrayTest from "sz-gray-test";

const SentryPlugin: PluginObject<Vue> = {
  install(Vue) {
    // 总体设置
    szGrayTest.init({
      isShowInstitution: 0, // 孝心商品预约选加项包及下单选加项包时允许查询机构
      isShowCustomQuestionIntroAnimation: 0.5, // 展示定制体检问卷首页动画
    });

    Vue.prototype.$szGrayTest = szGrayTest; // 挂到 Vue 的原型上，方便页面调用
  },
};

export default SentryPlugin;
