import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    currentInstitInfo: {},
  },
  getters: {
    currentInstitInfo: (state) => state.currentInstitInfo,
  },
  mutations: {
    // 存储当前选择机构信息
    setCurrentInstitInfo(state, layout) {
      state.currentInstitInfo = layout;
    },
  },
  actions: {},
};

export default GlobalModule;
