<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="sz-protocol" :class="textAlign">
    <label>
      <input v-model="isAgreeProto" type="checkbox" class="agree-checkbox" />
      {{ text }}
    </label>
    <span v-for="(item, index) in protocolList" :key="index">
      <span v-if="!notSplit && index > 0">{{ split }}</span>
      <a
        class="link"
        target="_blank"
        :href="`https://static.shanzhen.com/${item.contentPath[0]}`"
      >
        《{{ item.protocolName }}》
      </a>
    </span>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
export default {
  name: "",
  components: {},

  props: {
    // 第一句提示文案
    text: {
      type: String,
      default: "我已阅读并同意",
    },
    textAlign: {
      type: String,
      default: "center",
    },
    notSplit: {
      type: Boolean,
      default: false,
    },
    // 分隔字符串
    split: {
      type: String,
      default: "和",
    },
    protocolCodes: {
      type: Array,
      default: () => {
        return ["p0001"];
      },
    },
  },
  data() {
    return {
      isAgreeProto: false,
      protocolList: [], // 协议列表
    };
  },

  /* 一.生命周期函数 */
  created() {
    this.ajaxQueryProtocol();
  },

  mounted() {
    this.$emit("on-change", this.isAgreeProto);
  },

  /* 二.监控函数 */
  watch: {
    isAgreeProto() {
      this.$emit("on-change", this.isAgreeProto);
    },
  },

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 同意授权 - 外部授权完毕后调用
    actionAgree() {
      this.ajaxAddProtocolAgreeRecord();
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    doSomething() {},
    // 获取cookie
    getCookie(name) {
      var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      var arr = document.cookie.match(reg);
      if (arr) {
        return arr[2];
      } else {
        return null;
      }
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxQueryProtocol() {
      return this.$api
        .slget("cgi_protocolQueryList", {
          protocolCodeStr: this.protocolCodes.toString(),
          contentType: 1,
        })
        .then((res) => {
          if (res.SZ_HEAD.RESP_CODE === "S0000") {
            this.protocolList = res.SZ_BODY.PROTOCOL_LIST;
          }
        });
    },
  },
};
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.sz-protocol {
  color: #80848a;
  font-size: 0.24rem;
  line-height: 0.34rem;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &-label {
    padding: 5px;
  }
  .text-link {
    color: #318ff5 !important;
  }
  // 多选按钮
  .agree {
    &-checkbox {
      margin: 0;
      position: relative;
      top: 2px;
      width: 13px;
      height: 13px;
      font-size: 0;
      vertical-align: 0;
      background-color: #ffffff;
      border: 1px solid #d1d1d1;
      border-radius: 3px;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:checked::before {
        position: absolute;
        top: 50%;
        left: 60%;
        display: inline-block;
        content: "✔";
        color: #09bb07;
        font-size: 15px;
        font-family: "iconfont" !important;
        text-align: center;
        text-decoration: inherit;
        vertical-align: middle;
        transform: translate(-60%, -50%) scale(0.73);
      }
      &:disabled {
        background-color: #e1e1e1;
        &::before {
          color: #adadad;
        }
      }
    }
  }
}
.link {
  color: $sz-theme-color;
}
</style>
