import { render, staticRenderFns } from "./redEnvelope.vue?vue&type=template&id=5632997f&scoped=true&"
import script from "./redEnvelope.vue?vue&type=script&lang=ts&"
export * from "./redEnvelope.vue?vue&type=script&lang=ts&"
import style0 from "./redEnvelope.vue?vue&type=style&index=0&id=5632997f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5632997f",
  null
  
)

export default component.exports