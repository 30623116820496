const GOODSINDEX = () => import("@/views/goods/indexNew.vue");
const GOODSDESIGN = () => import("@/views/goods/goods-design.vue");
const GOODSLIST = () => import("@/views/goods/goods-list.vue");
const GOODSLISTV2 = () => import("@/views/goods/goods-list-v2.vue");
const GOODSDETAIL = () => import("@/views/goods/detail.vue");
const GOODSDETAILV2 = () =>
  import("@/views/goods/goods-detail-v2/goods-detail-v2.vue");
const ITEMSCONTAINER = () =>
  import("@/views/goods/components/itemsContainer/itemsContainer.vue");
const INSTITDETAIL = () => import("@/views/goods/instit-detail.vue");
const ENDORSE = () => import("@/views/goods/endorse.vue");
const basePath = "/goods";
export default [
  // 商品首页
  {
    path: basePath + "/goods_index",
    name: "goodsIndex",
    component: GOODSINDEX,
    meta: {
      title: "善太医体检",
    },
  },
  // 商品设计历程页
  {
    path: basePath + "/goods_design",
    name: "goodsDesign",
    component: GOODSDESIGN,
    meta: {
      title: "善太医",
    },
  },
  // 机构详情页
  {
    path: basePath + "/instit_detail",
    name: "institDetail",
    component: INSTITDETAIL,
    meta: {
      title: "善太医体检",
    },
  },
  // 信任背书落地页
  {
    path: basePath + "/endorse",
    name: "endorse",
    component: ENDORSE,
    meta: {
      title: "关于善太医",
    },
  },
  // 商品列表（新）`
  {
    path: basePath + "/goods_list_v2",
    name: "goodsListv2",
    component: GOODSLISTV2,
    meta: {
      title: "套餐列表",
    },
  },
  // 商品列表（旧）
  {
    path: basePath + "/goods_list",
    name: "goodsList",
    component: GOODSLIST,
    meta: {
      title: "套餐列表",
    },
  },
  // 商品详情v1
  {
    path: basePath + "/goods_detail-v1",
    name: "goodsDetail",
    component: GOODSDETAIL,
    meta: {
      title: "套餐详情",
    },
  },
  // 商品详情v2
  {
    path: basePath + "/goods_detail",
    name: "goodsDetailV2",
    component: GOODSDETAILV2,
    meta: {
      title: "套餐详情",
    },
  },
  // 自选检查项
  {
    path: basePath + "/items_container",
    name: "goodsItemsContainer",
    component: ITEMSCONTAINER,
    meta: {
      title: "自选检查项",
    },
  },
];
