/* eslint-disable no-sequences */
/*
 * @Author: zhengdaoyang
 * @Date: 2021-03-09 18:24:43
 * @LastEditTime: 2021-03-18 17:36:08
 * @LastEditors: zhengdaoyang
 * @Description:
 */
import {
  loadScript,
  isWechat,
  isWechatMini,
  isAlipayMini,
  isMiniProgram,
  getUa,
} from "@/module/tools";
import wx from "weixin-js-sdk";
const customPlugins = {};
customPlugins.install = (Vue) => {
  // 加载支付宝小程序web-view控件
  try {
    // 支付宝环境内才加载js文件，否则会报错
    (isWechat()
      ? loadScript("https://res.wx.qq.com/open/js/jweixin-1.6.0.js")
      : navigator.userAgent.includes("Alipay")
      ? loadScript("https://appx/web-view.min.js")
      : Promise.resolve()
    ).finally(() => {
      Vue.prototype.$ua = getUa();
      window.$ua = Vue.prototype.$ua;
      Vue.prototype.$isMiniProgram = isMiniProgram();
      window.$isMiniProgram = Vue.prototype.$isMiniProgram;
      Vue.prototype.$isAlipayMini = isAlipayMini();
      Vue.prototype.$isWechatMini = isWechatMini();

      // 小程序内 web-view redirectTo至小程序页面
      Vue.prototype.$miniRedirectTo = (url) => {
        if (isAlipayMini()) {
          my.redirectTo({ url });
          return;
        }
        if (isWechatMini()) {
          wx.miniProgram.redirectTo({ url });
        }
      };
      window.$miniRedirectTo = Vue.prototype.$miniRedirectTo;

      // 小程序内 web-view navigateTo至小程序页面
      Vue.prototype.$miniNavigateTo = (url) => {
        if (isAlipayMini()) {
          my.navigateTo({ url });
          return;
        }
        if (isWechatMini()) {
          wx.miniProgram.navigateTo({ url });
        }
      };
      window.$miniNavigateTo = Vue.prototype.$miniNavigateTo;
    });
  } catch (e) {
    console.log(e);
  }
};
export default customPlugins;
