import BaseAPI from "./base";

class API extends BaseAPI {
  /**
   * 查询加项商品
   * @param {String} goodsCode 商品编号
   * @returns 加项包数据
   */
  queryAddGoods(data: any) {
    return this.get("/is/wap/query/addGoods", data);
  }

  // 预约接口
  checkupReserve(data: any) {
    return this.slget("order_checkupReserve", data);
  }

  /**
   * 查询活动框架信息
   *
   * @param {String} activityCode 活动框架Code
   */
  queryActivity(activityCode: string) {
    return this.get("/is/activity/query/activity", {
      activityCode,
    });
  }

  /**
   * 查询商品列表
   * */
  queryGoodsList(activityCode: string) {
    return this.get("/is/wap/query/goodsList", {
      activityCode,
    });
  }

  /**
   * 查询商品详情
   * @param {String} goodsCode 商品编号
   * @returns 商品数据
   */
  queryGoodsDetail(activityCode: string, goodsCode: string) {
    return this.get("/is/wap/query/goodsDetail", {
      activityCode,
      goodsCode,
    });
  }

  /**
   * 下单到购物车
   * @param {Object} = {
   *  szUserId 登录用户ID query string
   *  szUserToken 登录用户token query string
   *  goodsInfoStr 商品信息 query string
   *  orderFrom 用户来源 query integer
   *  activityCode 营销活动编号 query string
   * }
   * @returns 下单数据
   */
  examShoppingCartOrdered(data: any) {
    return this.slget("order_examShoppingCartOrdered", data);
  }

  /**
   * @param {Object} = {
   *  cartBacthCode 购物车批次号 query string
   * }
   */
  examCheckupOrdered(cartBatchCode: string) {
    return this.slget("order_examCheckupOrdered", {
      cartBatchCode,
    });
  }

  // 老用户升级为VIP
  upgradeOldUserToVipUser() {
    return this.get("/is/wap/upgrade/oldUserToVipUser");
  }

  // 老用户下单数
  oldUserOrderStatistics() {
    return this.get("/is/wap/query/oldUserOrderStatistics");
  }

  // 新用户购买会员下单
  checkupVipOrder() {
    return this.get("/is/wap/checkup/vipOrder");
  }

  // 查询一代报告异常标签
  queryReportPreTag(orderCode: string) {
    return this.get("/is/wap/query/reportPreTag", {
      orderCode,
    });
  }

  // 查询一代报告详情
  queryReportLevel1Detail(data: any) {
    return this.get("/is/wap/query/reportLevel1Detail", data);
  }

  // 查询一代报告图片文件
  queryReportLevel1File(data: any) {
    return this.get("/is/wap/query/reportLevel1File", data);
  }

  // 二代报告查询体检概况
  queryReportSummaryByOrderCode(data: any) {
    return this.get("/is/wap/query/reportSummaryByOrderCode", data);
  }

  // 二代报告查询异常报告
  queryReportAbnormalItemsByOrderCode(data: any) {
    return this.get("/is/wap/query/reportAbAbnormalItemsByOrderCode", data);
  }

  // 二代报告查询详情
  queryReportItemsByOrderCode(data: any) {
    return this.get("/is/wap/query/reportItemsByOrderCode", data);
  }

  // 二代报告异常详情
  queryReportAbnormalSummary(data: any) {
    return this.get("/is/wap/query/reportAbnormalSummary", data);
  }

  // 二代报告查询报告语音
  queryReportMedia(orderCode: string) {
    return this.get("/is/wap/query/reportMedia", {
      orderCode,
    });
  }

  // 医生列表查询
  queryDoctorList(data: any) {
    return this.get("/is/wap/query/doctor_list", data);
  }

  // 报告解读下单
  interpretOrdered(data: any) {
    return this.slget("order_interpretOrdered", data);
  }

  // 查询解读订单详情
  interpretDetail(data: any) {
    return this.get("/is/wap/query/interpret_detail", data);
  }

  // 判断用户是否评价过，为ture表示评价过，不允许再次评价
  checkEvaluate(orderCode: string, evaluateBizType = "001") {
    return this.get("/is/wap/check/evaluate", {
      orderCode,
      evaluateBizType,
    });
  }

  // 语音评价获取评价模板
  queryTempEvaluateList({
    evaluateType,
    orderCode,
    evaluateBizType = "001",
  }: any) {
    return this.get("/is/wap/query/tempEvaluateList", {
      orderCode,
      evaluateBizType,
      evaluateType,
    });
  }

  // 保存评价列表
  saveEvaluateList(data: any) {
    data.evaluateBizType = "001";
    return this.get("/is/wap/save/evaluateList", {
      evaluateDto: JSON.stringify(data),
    });
  }

  // 取消订单
  examCancel(data: any) {
    return this.get("/is/wap/exam/cancel", data);
  }

  // 查询城市
  queryRegions(data: any) {
    return this.get("/is/wap/query/regions", data);
  }

  // 改约
  changeReserveDate(data: any) {
    return this.get("/is/wap/change/reserveDate", data);
  }

  // 查询机构
  queryInstits(data: any) {
    return this.get("/is/wap/query/instits", data);
  }

  // 查询预约机构更多时间
  queryReserveDays(data: any) {
    return this.slget("region_days", data);
  }

  // 查询预约信息
  queryReseveOrderDetail(data: any) {
    return this.get("/is/wap/query/reseveOrderDetail", data);
  }

  // 查询可用性别
  querySexListByGoodsCode(data: any) {
    return this.get("/is/wap/query/sexListByGoodsCode", data);
  }

  // 查询体检订单详情
  queryOrderExamSummary(data: any) {
    return this.get("/is/wap/query/orderExamSummary", data);
  }

  //  支付健康金订单创建
  orderHealthGoldordered(data: any) {
    return this.slpost("cgi_orderHealthGoldordered", data);
  }

  //  健康金获赔人数
  orderHealthGoldQueryCount(data: any) {
    return this.slpost("cgi_orderHealthGoldQueryCount", data);
  }

  // 新的服务型体检订单详情查询
  serviceExamOrderDetail(data: any) {
    return this.post("/is/wap/query/serviceExamOrderDetail", data);
  }

  // TODO：待删除 旧的查询服务型体检订单详情（2019-01-25）
  serviceExamSummary(data: any) {
    return this.post("/is/wap/query/serviceExamSummary", data);
  }

  // 新的服务型体检下单接口
  serviceExamCheckupOrderNew(data: any) {
    return this.post("/is/wap/service/serviceExamCheckupOrderNew", data);
  }

  // 取消推荐加项包
  disableRecommendAddGoods(data: any) {
    return this.post("/is/wap/goods/disableRecommendAddGoods", data);
  }

  // ****** TODO：下单接口三合一 旧的服务型体检下单接口（待删除）
  serviceExamCheckupOrder(data: any) {
    return this.post("/is/wap/service/examCheckupOrder", data);
  }

  // 服务型商品下单（1元购和家庭医生服务）
  serviceOrdered(data: any) {
    return this.post("/is/wap/service/serviceOrdered", data);
  }

  // 服务型体检混合下单接口（定制体检 + 家庭医生商品）
  compositionOrdered(cartBatchCode: string) {
    return this.post("/is/wap/service/compositionOrdered", {
      cartBatchCode,
    });
  }

  // 1元定制下单查询接口
  serviceOrderQuery(data: any) {
    return this.post("/is/wap/service/serviceOrderQuery", data);
  }

  // 查询收银台信息
  queryCashierInfo(data: any) {
    return this.get("/is/wap/query/cashierInfo", data);
  }

  // 新的查询服务型体检列表
  serviceExamOrderList(data: any) {
    return this.get("/is/wap/query/serviceExamOrderList", data);
  }

  // 查询体检订单列表
  queryOrderList(data: any) {
    return this.get("/is/wap/query/orderList", data);
  }

  // 查询健康服务订单列表
  queryHealthList(data: any) {
    return this.get("/is/wap/familyHealth/familyHeathOrderList", data);
  }

  // 查询报告列表
  queryReportList({ currentPage, pageSize }: any) {
    return this.get("/is/wap/query/reportList", {
      currentPage,
      pageSize,
    });
  }

  // C端用户发送手机号登录验证码
  sendVerifycode({ mobile, graphCode, noGraph }: any) {
    return this.post("/is/wap/logi/sendVerifycode", {
      mobile,
      graphCode,
      noGraph,
      usf: "405",
    });
  }

  // C端用户手机号登录
  login({ mobile, verifyCode, graphCode, sendCodeType }: any) {
    return this.get("/is/wap/prelogin/login", {
      mobile,
      verifyCode,
      graphCode,
      usf: "405",
      sendCodeType,
    });
  }

  cBindMobile(data: any) {
    return this.post("/is/user/prec_bind_mobile", data);
  }

  cChangegMobile(data: any) {
    return this.post("/is/user/c_changeg_mobile", data);
  }

  // 服务型体检定制体检订单号绑定
  serviceBindOrderCode(data: any) {
    return this.slpost("custom_bind_ordercode", data);
  }

  // 支付接口
  directPay(data: any) {
    if (data.payType === "WXPAY") {
      data.payPlatform = process.env.VUE_APP_WX_PAY_PLAT_FORM;
    } else if (data.payType === "ALIPAY") {
      data.payPlatform = process.env.VUE_APP_ALPAY_PAY_PLAT_FORM;
    }

    return this.get("/is/wap/pay/directPay", data);
  }

  // 支付回调
  appPayReturn({ payOrderCode }: { payOrderCode: string }) {
    return this.get("/is/wap/pay/appPayReturn", {
      payOrderCode,
    });
  }

  // 登出
  loginOut() {
    return this.get("/is/wap/logi/loginOut");
  }

  // 获取医生列表
  getMedicalDoctorList() {
    return this.get("/is/wap/ask/get_medical_doctor_list");
  }

  // 独立问诊 获取医生列表
  getSpringRainMedicalDoctorList() {
    return this.get("/is/wap/ask/get_spring_rain_medical_doctor_list");
  }

  // 查询问诊订单列表
  queryMedicalOrderList(data: any) {
    return this.post("/is/wap/ask/medical_order_list", data);
  }

  // 问诊收银台
  getMedicalCashierInfo(data: any) {
    return this.get("/is/wap/ask/get_medical_cashierInfo", data);
  }

  // 查询问诊聊天记录
  getMedicalDialogue(data: any) {
    return this.post("/is/wap/ask/get_medical_dialogue", data);
  }

  // 问诊用户答复接口
  medicalUserReply(data: any) {
    return this.post("/is/wap/ask/medical_user_reply", data);
  }

  // 查询小贴士相关信息
  queryHealthTip(data: any) {
    return this.get("/is/wap/query/healthTip", data);
  }

  // 获取微信SDK配置
  getSdkConfig() {
    return this.slpost("qywx_jsapi_app", { szpl: "SZYOUXUAN01" });
  }

  // 获取微信SDK配置
  getGraphic(data: any) {
    return this.get("/is/user/get_graphic", data);
  }

  /**
   * 查询商品列表，列名可配置
   * @param {Object} data {
   *   activityCode 活动框架Code
   *   filterBlank 是否过滤系列下商品为空的集合,默认为false
   * }
   */
  goodsSeriesList(data: any) {
    return this.get("/is/wap/query/goodsSeriesList", data);
  }

  /**
   * 家庭医生订单-订单列表
   * @param data
   * @returns {*}
   */
  familyHeathOrderList(data: any) {
    return this.get("/is/wap/familyHealth/familyHeathOrderList", data);
  }

  /**
   * 家庭医生订单-订单详情
   * @param data
   * @returns {*}
   */
  familyHeathOrderDetail(data: any) {
    return this.get("/is/wap/familyHealth/familyHeathOrderDetail", data);
  }

  /**
   * 家庭医生--配置化二维码
   * @param data
   * @returns {*}
   */
  familyHeathQrCode(data: any) {
    return this.get("/is/wap/familyHealth/familyHeathQrCode", data);
  }

  // 获取首页数据
  homeShow() {
    return this.get("/is/wap/home/show", {}, { cache: true });
  }

  // 口令兑换领取权益接口
  jdDrawBenefits(data: any) {
    return this.post("/is/jd/draw/benefits", data);
  }

  // 权益查询接口
  jdQueryBenefit(data: any) {
    return this.post("/is/jd/query/benefit", data);
  }

  // 用户权益查询接口
  jdQueryUserBenefit(data: any) {
    return this.post("/is/jd/query/user/benefit", data);
  }

  // 权益下单接口
  checkupOrder(data: any) {
    return this.post("/is/checkupOrder", data);
  }

  // 兑换券接口
  jdExchangeCoupon(data: any) {
    return this.slget("order_jdExchangeCoupon", data);
  }

  // 加项包下单（在基础商品的基础上）
  addGoodsOrder(data: any) {
    return this.slget("order_addGoodsOrder", data);
  }

  // 查询常用预约人
  reservedUsers(data: any) {
    return this.post("/is/query/reserve/reservedUsers", data);
  }

  // 获取用户协议
  getProtocol(data: any) {
    return this.get("/is/query/protocol/query/detail", data);
  }

  // 查询体检人列表
  getExaminations(data = {}) {
    return this.get("/is/query/function/recall/query/examination", data);
  }

  // 功能性复购商品详情页接口
  queryRecommendDetail(data: any) {
    return this.post("/is/query/recommend/query", data);
  }

  // 功能性复购新旧订单绑定接口
  recallOrderBind(data: any) {
    return this.post("/is/query/function/recall/bind", data);
  }

  // 生成小程序码或者公众号二维码
  /*
    http://wiki.wayyue.com/pages/viewpage.action?pageId=10126399（二）
     */
  generateOrCode(data: any) {
    return this.post("/is/wap/wx_qrcode/generate", data);
  }

  // 获取推荐健康课课程列表
  getCourseBannerList(data: any) {
    return this.post("/is/wap/course/getCourseBannerList", data);
  }

  // 查询当前登陆人是否有未支付的小程序家庭健康订单
  existsNotPayOrder(data: any) {
    return this.get("/is/wap/course/existsNotPayOrder", data);
  }

  // 获取推荐健康课课程列表
  qrCodeUpdate(data: any) {
    return this.post("/is/wap/course/qrCodeUpdate", data);
  }

  // 兑换券接口
  exchangeCoupon(data: any) {
    return this.slget("mkt_couponExchange", data);
  }

  // 查询优惠券列表
  queryCouponList(data: any) {
    return this.slpost("mkt_canUseCoupon", data);
  }

  // 获取抵扣信息
  getDiscountInfo(data: any) {
    return this.slget("mkt_discountInfo", data);
  }

  // 30天饮食打卡订单状态
  isExistsOrder(activityCode: string, goodsCode: string) {
    return this.slget("order_isExistsOrder", {
      activityCode,
      goodsCode,
    });
  }

  // 查询支持公立医院的城市列表
  queryHospitalCitys(data: any) {
    return this.slget("region_hospital_citys", data);
  }

  // 查询某个城市下的公立医院列表
  queryHospitalList(data: any) {
    return this.slget("region_hospital_list", data);
  }

  // 查询某个公立医院支持的商品列表
  queryHospitalGoodsList(data: any) {
    return this.slget("goods_hospital_goodsList", data);
  }

  // 申请开通城市
  applyInstit(data: any) {
    return this.slget("apply_instit", data);
  }

  // 用户授权善太医从机构处读取报告
  authViewReport(data: any) {
    return this.slget("authViewReport", data);
  }

  // 查询机构差异项
  queryInstitTips(data: any) {
    return this.slget("queryInstitTips", data);
  }

  // 加急报告
  urgeReport(data: any) {
    return this.slget("urgentReport", data);
  }

  // 查询福利
  silentWelfareUpgrade(data: any) {
    data.encryptedData = sessionStorage.getItem("coupon_encryptedData") || "";
    data.iv = sessionStorage.getItem("coupon_iv") || "";
    data.pid = sessionStorage.getItem("coupon_pid") || "";
    return this.get("/is/sl/mkt_couponPresent", data);
  }
}

export default API;
