/*
 * @Author: 王浩
 * @Date: 2021-06-18 15:15:26
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-11-30 21:13:47
 * 测试用中间件
 */
import { Route } from "vue-router";
import Vue from "vue";
import ErudaPlugin from "@/module/plugins/eruda";

const middleware = {
  mode: "once", // once | interval
  fn(to: Route, from: Route): Promise<void> {
    return new Promise((resolve) => {
      /**
       * 这里 Eruda 相对于其它组件为异步加载
       * 但是由于 Eruda 异步加载可能会导致控制台日志丢失
       * 所以在这里设置 Eruda 加载完毕后再 resolve
       * 注意：这里的异步仅在开发测试、debug 过程中会同步加载，
       * 并不会影响到生产环境的用户
       */
      Vue.use(ErudaPlugin, resolve);
      console.log("[middleware2 同步执行 循环执行]", to, from);
    });
  },
};

export default middleware;
