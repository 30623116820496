/*
 * @Description: 在此输入描述
 * @Date: 2019-10-10 09:55:13
 * @Author: 郑道杨
 * @LastEditors: 郑道杨
 * @LastEditTime: 2019-10-23 11:17:58
 */
const reportLevel1Detail = () =>
  import("@/views/report/reportLevel1Detail.vue");
const reportIndex = () => import("@/views/report/reportIndex.vue");
const reportAbnormal = () => import("@/views/report/reportAbnormal.vue");
import { RouteConfig } from "vue-router";
const basePath = "/report";
const Routes: Array<RouteConfig> = [
  {
    path: basePath + "/level1_detail",
    name: "reportLevel1Detail",
    component: reportLevel1Detail,
    meta: {
      title: "报告",
      hideMenuItems: [
        "menuItem:copyUrl", // 复制链接
      ],
    },
  },
  {
    path: basePath + "/index",
    name: "reportIndex",
    component: reportIndex,
    meta: {
      title: "报告",
      hideMenuItems: [
        "menuItem:copyUrl", // 复制链接
      ],
    },
  },
  {
    path: basePath + "/abnormal",
    name: "reportAbnormal",
    component: reportAbnormal,
    meta: {
      title: "异常详情",
    },
  },
];
export default Routes;
