/*
 * @Author: 王浩
 * @Date: 2021-06-17 17:40:47
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-11-29 14:59:59
 * 新用户福利
 */

import Vue from "vue";
import { getCookie } from "@/module/tools";
const oGlobal = Vue.prototype;

function newUserWelfare(): void {
  // 未登录，返回
  if (getCookie("islogin") !== "1") return;
  // 调用过接口，不再调用
  if (localStorage.getItem("new-user-welfare-flag") === "1") return;

  oGlobal.$api
    .slget("examine_filialPackageGuide")
    .then((res: any) => {
      if (res.SZ_HEAD.RESP_CODE === "S0000") {
        const { showNotify, pushCoupon } = res.SZ_BODY;
        if (showNotify) {
          oGlobal.$event.$emit("new-user-welfare", pushCoupon);
        }
      } else {
        oGlobal.$toast(res.SZ_HEAD.RESP_MSG);
      }
    })
    .finally(() => {
      // 置为1，当前访问周期内不再调用
      localStorage.setItem("new-user-welfare-flag", "1");
    });
}

export default newUserWelfare;
