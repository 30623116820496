/*
 * @Author: 王浩
 * @Date: 2021-07-07 10:03:59
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-07-07 10:16:51
 * router入口文件，此处内容无特殊情况请勿修改
 * 用来遍历添加路由
 */

/*  eslint-disable */
import { RouteConfig } from "vue-router";

const files = require.context('.', false, /\.(j|t)s$/)
const routers: Array<RouteConfig> = []

files.keys().forEach(key => {
  if (key === './index.ts') return
  // console.log('files', key.replace(/(\.\/|\.js)/g, ''), files(key).default)
  // 如果是数组，表示是others的路由，所以需要解构添加
  if (files(key).default instanceof Array) {
    routers.push(...files(key).default)
  } else {
    routers.push(files(key).default)
  }
})

export default routers
