/*
 * @Author: 王浩
 * @Date: 2021-07-06 17:05:29
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-11-29 14:25:26
 * DB 插件
 */
import Vue, { PluginObject } from "vue";
import szIndexedDB from "sz-indexdb";

const IndexedDBPlugin: PluginObject<Vue> = {
  install(Vue) {
    const DB_CONFIG = {
      name: "SZ_CUSTOM",
      version: 1,
      cacheTime: 300 * 1000,
      objectStoreList: [
        {
          name: "WX_SHARE", // 数据对象名称
          keyPath: "primaryKey", // 主键
          autoIncrement: false, // 主键是否自增
        },
        {
          name: "HOMEPAHE", // 数据对象名称
          keyPath: "primaryKey", // 主键
          autoIncrement: false, // 主键是否自增
        },
        {
          name: "ACTIVITY_INFO", // 数据对象名称
          keyPath: "primaryKey", // 主键
          autoIncrement: false, // 主键是否自增
        },
        {
          name: "CUSTOM_V4_QUESTION_CACHE", // 定制体检题目暂存
          keyPath: "primaryKey",
          autoIncrement: false,
        },
        {
          name: "CUSTOM_V4_CURRENT_REQUEST_PARAMS_CACHE", // 定制体检当前请求参数
          keyPath: "primaryKey",
          autoIncrement: false,
        },
        {
          name: "REGIONS", // 地区数据
          keyPath: "primaryKey",
          autoIncrement: false,
        },
      ],
    };

    Vue.prototype.$db = new szIndexedDB(DB_CONFIG);
  },
};

export default IndexedDBPlugin;
