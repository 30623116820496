const tips = () => import("@/views/tips/tips.vue");

const basePath = "/tips";
export default [
  // 下单页
  {
    path: basePath + "/tips",
    name: "tips",
    component: tips,
    meta: {
      title: "友情提示",
    },
  },
];
