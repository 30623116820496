const userIndex = () => import("@/views/user/index.vue");
const userSetting = () => import("@/views/user/setting.vue");
const userProblemCenter = () => import("@/views/user/problemCenter.vue");
const userTicket = () => import("@/views/user/ticket.vue");
const userOrderList = () => import("@/views/user/order_list.vue");
const refund = () => import("@/views/user/refund.vue"); // 退款详情
const problem = () => import("@/views/user/problemCenter.vue"); // 常见问题
const authOption = () => import("@/views/user/authOption.vue"); // 授权选项
const customHistory = () => import("@/views/user/customHistory.vue"); // 定制历史页面

const basePath = "/user";
export default [
  // 个人中心页
  {
    path: basePath + "/index",
    name: "userIndex",
    component: userIndex,
    meta: {
      title: "我的",
    },
  },
  {
    path: basePath + "/order_list",
    name: "userOrderList",
    component: userOrderList,
    meta: {
      title: "体检订单",
    },
  },
  {
    path: basePath + "/tj_detail",
    name: "userOrderDetail",
    component: () => import("@/views/user/orderDetail"),
    meta: {
      title: "订单详情",
    },
  },
  {
    path: basePath + "/setting",
    name: "userSetting",
    component: userSetting,
    meta: {
      title: "设置",
    },
  },
  {
    path: basePath + "/problem_center",
    name: "userProblemCenter",
    component: userProblemCenter,
    meta: {
      title: "问题中心",
    },
  },
  {
    path: basePath + "/ticket",
    name: "userTicket",
    component: userTicket,
    meta: {
      title: "我的抵扣券",
    },
  },
  // 关于我们
  {
    path: basePath + "/about",
    name: "about",
    component: () => import("@/views/user/about.vue"),
    meta: {
      title: "　",
    },
  },
  {
    path: basePath + "/refund",
    name: "refund",
    component: refund,
    meta: {
      title: "退款申请",
    },
  },
  {
    path: basePath + "/problemCenter",
    name: "problem",
    component: problem,
    meta: {
      title: "　",
    },
  },
  {
    path: basePath + "/auth_option",
    name: "authOption",
    component: authOption,
    meta: {
      title: "　",
    },
  },
  {
    path: basePath + "/custom_history",
    name: "customHistory",
    component: customHistory,
    meta: {
      title: "我的方案",
    },
  },
];
