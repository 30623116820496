import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/scss/main.scss";
import "@/module/libs/rem.js";
import "@/assets/fonts/font.js";
import "@/components";

// 插件相关
import GlobalVarPlugin from "@/module/plugins/globalVar";
import ApiPlugin from "@/module/plugins/api";
import SentryPlugin from "@/module/plugins/sentry";
import SensorPlugin from "@/module/plugins/sensor";
import ABTestPlugin from "@/module/plugins/abTest";
import IndexedDBPlugin from "@/module/plugins/db";
import MiniProgramPlugin from "@/module/plugins/miniProgramPlugin";

// 添加插件
Vue.use(GlobalVarPlugin)
  .use(ApiPlugin)
  .use(SentryPlugin)
  .use(SensorPlugin)
  .use(ABTestPlugin)
  .use(IndexedDBPlugin)
  .use(MiniProgramPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
