import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    activityName: "", // 活动名称
    activityCode: "", // 活动框架code
    comId: "",
    activityType: "", // 活动类型（CUSTOM_COOPERATION_AUTHORIZE : 定制体检异业合作-免费基础-授权领取, CUSTOM_COOPERATION：定制体检异业合作-免费基础，CUSTOM_COOPERATION_PASS_EXCHANGE：定制体检异业合作-口令兑换）
    spreadTarget: "", // 导流方式
  },
  getters: {
    activityName: (state) => state.activityName,
    activityCode: (state) => state.activityCode,
    comId: (state) => state.comId,
    activityType: (state) => state.activityType,
    spreadTarget: (state) => state.spreadTarget,
  },
  mutations: {
    // 批量设置值
    setActivityInfo(state, obj) {
      Object.assign(state, obj);
    },
    setActivityCode(state, activityCode) {
      state.activityCode = activityCode;
    },
    setComId(state, comId) {
      state.comId = comId;
    },
    setActivityType(state, activityType) {
      state.activityType = activityType;
    },
  },
  actions: {
    async setActivityCode({ commit }, activityCode) {
      commit("setActivityCode", activityCode);
    },
    async setComId({ commit }, setComId) {
      commit("setComId", setComId);
    },
    async setActivityType({ commit }, activityType) {
      commit("setActivityType", activityType);
    },
  },
};

export default GlobalModule;
