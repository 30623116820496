import Vue from "vue";
import Vuex, { Store } from "vuex";
import { RootState } from "./types";

// 数据持久化插件
import createPersistedState from "vuex-persistedstate";

// 导入模块
import globalModule from "./modules/global";
import homeModule from "./modules/home";
import activityInfoModule from "./modules/activityInfo"; // 活动信息模块
import appointmentModule from "./modules/appointment"; // 预约模块
import customV4Module from "./modules/customV4"; // 预约模块
import orderModule from "./modules/order"; // 订单模块
import report from "./modules/report"; // 报告模块，来自于 wap2 的模块联邦导出
import goods from "./modules/goods"; // 商品模块，

Vue.use(Vuex);

export declare class MyStore extends Store<RootState> {
  global?: any;
}

const store: MyStore = new Vuex.Store({
  plugins: [createPersistedState({ key: "vuex_persistedstate_custom" })],
  modules: {
    $_global: globalModule,
    $_home: homeModule,
    $_activityInfo: activityInfoModule,
    $_appointment: appointmentModule,
    $_customV4: customV4Module,
    $_order: orderModule,
    $_report: report,
    $_goods: goods,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});

// 兼容旧的逻辑
store.global = {
  set(key: string, value: any) {
    store.commit("$_global/setState", {
      [key]: value,
    });
  },
};

console.log(store.state);

Object.defineProperty(store.global, "state", {
  get() {
    return store.getters["$_global/data"];
  },
});

export default store;
