/**
 * 美年大健康授权电子报告
 */

const authorise = () =>
  import(
    /* webpackChunkName: "authorizationAuthorise" */ "@/views/authorization/authorise.vue"
  );
const result = () =>
  import(
    /* webpackChunkName: "authorizationResult" */ "@/views/authorization/result.vue"
  );

const basePath = "/authorization";

export default [
  {
    path: basePath + "/authorise",
    name: "authorizationAuthorise",
    component: authorise,
    meta: {
      title: "授权获取报告",
    },
  },
  {
    path: basePath + "/result",
    name: "authorizationResult",
    component: result,
    meta: {
      title: "授权获取报告",
    },
  },
];
