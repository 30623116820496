/*
 * @Author: zhengdaoyang
 * @Date: 2020-09-14 10:27:02
 * @LastEditTime: 2021-12-09 15:06:43
 * @LastEditors: zhengdaoyang
 * @Description:
 */
const PAY = () => import("@/views/pay/pay.vue");
const mvpPay = () => import("@/views/pay/mvpPay.vue");
const reportPay = () => import("@/views/pay/reportPay.vue");
const medicalPay = () => import("@/views/pay/medicalPay.vue");
const promptPay = () => import("@/views/pay/prompt-pay.vue");
const customv4Pay = () => import("@/views/pay/customv4Pay.vue");

const basePath = "/pay";
export default [
  // 统一支付收银台
  {
    path: basePath + "/cashier",
    name: "cashier",
    component: () => import("@/views/pay/cashier"),
    meta: {
      title: "收银台",
    },
  },
  // 支付页
  {
    path: basePath + "/pay",
    name: "pay",
    component: PAY,
    meta: {
      title: "支付",
    },
  },
  {
    path: basePath + "/mvppay",
    name: "mvpPay",
    component: mvpPay,
    meta: {
      title: "支付",
    },
  },
  // 报告解读下单页
  {
    path: basePath + "/report_pay",
    name: "reportPay",
    component: reportPay,
    meta: {
      title: "报告解读",
    },
  },
  // 问诊支付页
  {
    path: basePath + "/medical_pay",
    name: "medicalPay",
    component: medicalPay,
    meta: {
      title: "支付",
    },
  },
  // 立即支付（在当前页直接支付）
  {
    path: basePath + "/prompt-pay",
    name: "promptPay",
    component: promptPay,
    meta: {
      title: "　",
      hideMenuItems: [
        "menuItem:copyUrl", // 复制链接
      ],
    },
  },
  // 定制体检v4支付（在当前页直接支付）
  {
    path: basePath + "/customv4_pay",
    name: "customv4Pay",
    component: customv4Pay,
    meta: {
      title: "善太医体检",
    },
  },
  // 优选体检预约页 - 这里有健康金支付的逻辑，所以需要放到支付里
  {
    path: basePath + "/appointmentInfo",
    name: "payAppointmentInfo",
    component: () =>
      import(/* webpackChunkName: "addGoods" */ "@/views/pay/appointmentInfo"),
    meta: {
      title: "预约体检",
    },
  },
];
