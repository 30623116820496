
import { isWechatMini } from "@/module/tools";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
@Component({
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    // About: () => import("../About.vue"),
  },
})
export default class Home extends Vue {
  @Prop() private value!: boolean;
  isShow = false;
  isHasShow = false; //是否已展示过红包弹窗
  couponList: any = [];
  bgImg = "";
  comId = [
    "e33c26e851474cfa990c1a04af77f012",
    "d38486c402fa4719bb36edb01a7f82a5",
  ]; // 平台券的comId组
  get isNeedPopup() {
    // 小程序内不展示红包弹窗
    if (isWechatMini()) return false;

    return (
      this.$getCookie("islogin") === "1" &&
      this.$getCookie("maskMobile") &&
      this.isHasShow &&
      this.isShow
    );
  }

  /* 一.生命周期函数 */
  created(): void {
    if (
      this.$getCookie("islogin") === "1" &&
      this.$getCookie("maskMobile") &&
      !localStorage.getItem("sz_custom_red_envelope")
    ) {
      this.isHasShow = true;
      localStorage.setItem("sz_custom_red_envelope", "1");
    }
    if (
      this.$getCookie("islogin") === "1" &&
      this.$getCookie("maskMobile") &&
      this.isHasShow
    ) {
      this.ajaxQueryStyCouponList();
    }
  }

  /* 二.监控函数 */

  /* 三.内部功能函数 */

  /* ----------------------事件调用函数------------------------ */
  // 主动关闭弹窗
  actionClose(num) {
    let description = "";
    if (num === 1) {
      description = "领券并使用";
    } else if (num === 2) {
      description = "点击关闭";
    }
    this.$sa.track("click", {
      page_name: "红包弹窗",
      description: description,
    });
    this.$emit("change", false);
  }

  /* ----------------------内部功能函数------------------------ */
  // 内部功能函数注释
  doSomething() {}

  /* ----------------------服务请求函数------------------------ */
  // 服务请求函数注释
  ajaxXxx() {}
  ajaxQueryStyCouponList() {
    return this.$api.slpost("examine_queryStyCouponList").then((res) => {
      if (res.SZ_HEAD.RESP_CODE === "S0000") {
        this.isShow = true;
        this.$sa.track("page_view", {
          page_name: "红包弹窗",
          view_name: "查看红包弹窗",
        });
        const { couponOtherList } = res.SZ_BODY;
        this.couponList = this.couponList.concat(couponOtherList);
        if (this.couponList.length === 2) {
          this.bgImg = require("@/assets/images/red-envelope/is_old.png");
        } else if (this.couponList.length === 3) {
          this.bgImg = require("@/assets/images/red-envelope/is_new.png");
        }
        console.warn(this.couponList);

        const PromiseArr = couponOtherList.map((item) => {
          if (item.couponNum >= 2) return Promise.resolve();

          if (item.couponType !== 8) {
            return this.getQueryUserObtainWelfare(this.comId, item);
          } else {
            return this.ajaxSilentWelfareUpgrade(
              "b31190708b9d4ce3a2115e7be290770f"
            );
          }
        });
        return Promise.all(PromiseArr);
      } else {
        this.isShow = false;
      }
    });
  }
  // 获取优惠金额
  getQueryUserObtainWelfare(comIdList, coupon) {
    return this.$api
      .slpost("mkt_queryUserObtainWelfareByMultiActivity", {
        comIdList: comIdList,
      })
      .then((res) => {
        if (res.SZ_HEAD.RESP_CODE === "S0000") {
          // 根据券的couponCode查找相应的comId，这个接口返回字段为welfareTypeValue
          let currentCoupon = res.SZ_BODY.find(
            (item) => item.welfareTypeValue === coupon.couponCode
          );

          if (currentCoupon?.comId) {
            return this.ajaxSilentWelfareUpgrade(currentCoupon.comId);
          }
        }
      });
  }

  // 塞券
  ajaxSilentWelfareUpgrade(comId) {
    console.log("[ajaxSilentWelfareUpgrade 调用]");
    return this.$api.slpost("mkt_couponPresent", {
      comId,
    });
  }
}
