const RESULT = () => import("@/views/service/result.vue");
const SERVICEEXPLAIN = () => import("@/views/service/serviceExplain.vue");
const FAMILYHEALTHASSITANT = () =>
  import("@/views/service/familyHealthAssitant.vue");
const SPECIALCOURSELIST = () => import("@/views/service/specialCourseList.vue");
const COURSESTORY = () => import("@/views/service/courseStory.vue");
const FREECOURSEDETAIL = () => import("@/views/service/freeCourseDetail.vue");
const buyCustomServiceSuccess = () =>
  import("@/views/service/buyCustomServiceSuccess.vue");

const basePath = "/service";
export default [
  // 结果页
  {
    path: basePath + "/result",
    alias: "/custom/custom/result",
    name: "serviceResult",
    component: RESULT,
    meta: {
      title: "立即扫码，开始定制",
    },
  },
  // 家庭医生服务详情页
  {
    path: basePath + "/freeCourseDetail",
    alias: "/custom/custom/freeCourseDetail",
    name: "freeCourseDetail",
    component: FREECOURSEDETAIL,
    meta: {
      title: "家庭健康课",
    },
  },
  // 家庭医生协议
  {
    path: basePath + "/serviceExplain",
    name: "serviceExplain",
    component: SERVICEEXPLAIN,
    meta: {
      title: "善太医健康服务用户协议",
    },
  },
  // 家庭医生健康助手页面
  {
    path: basePath + "/familyHealthAssitant",
    name: "familyHealthAssitant",
    component: FAMILYHEALTHASSITANT,
    meta: {
      title: "善太医健康服务",
    },
  },
  {
    path: basePath + "/specialCourseList",
    name: "specialCourseList",
    component: SPECIALCOURSELIST,
    meta: {
      title: "善太医健康服务",
    },
  },
  {
    path: basePath + "/courseStory",
    name: "courseStory",
    component: COURSESTORY,
    meta: {
      title: "善太医健康服务",
    },
  },
  {
    path: basePath + "/custom_result",
    name: "buyCustomServiceSuccess",
    component: buyCustomServiceSuccess,
    meta: {
      title: "支付成功",
    },
  },
];
