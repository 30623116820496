
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import redEnvelope from "@/views/home/components/redEnvelope.vue";

// https://cn.vuejs.org/v2/guide/typescript.html
// https://github.com/vuejs/vue-class-component
@Component({
  components: {
    redEnvelope,
  },
})
export default class Home extends Vue {
  @Prop() private propData!: string;
  msg = "消息提示";
  isShow = true; // 是否展示筛选弹窗

  /* 一.生命周期函数 */
  created(): void {}

  /* 二.监控函数 */
  @Watch("isSHow")
  onPopupClose(val) {
    if (!val) {
      this.actionToClosePopup();
    }
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  // 事件调用函数注释
  actionXxx(): void {}
  actionToClosePopup() {
    this.isShow = false;
  }

  /* ----------------------内部功能函数------------------------ */
  // 内部功能函数注释
  doSomething(): void {}

  /* ----------------------服务请求函数------------------------ */
  // 服务请求函数注释
  ajaxXxx(): void {}
}
