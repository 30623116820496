<template>
  <div v-show="!hideHeader.includes(ua)" class="sz-page-header">
    <div class="sz-page-header-left">
      <sz-icon
        v-show="!hideBack"
        name="back"
        class="icon ft20"
        @click="goBack"
      ></sz-icon>
    </div>
    <div class="sz-page-header-body ft18">
      <slot>{{ title }}</slot>
    </div>
  </div>
</template>

<script>
import { getUa } from "@/module/tools";

export default {
  name: "",
  data() {
    return {
      ua: getUa().platform,
      hideHeader: ["alipay"], // 隐藏头部的ua.app
    };
  },
  props: {
    back: {
      type: Function,
      default: null,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    goBack() {
      if (this.back) {
        this.back();
      } else {
        history.go(-1);
        // this.$router.back()
      }
    },
  },
  computed: {
    isShow() {
      return document.title !== "";
    },
    title() {
      console.log(document.title);
      return document.title;
    },
  },
};
</script>

<style scoped lang="scss">
.sz-header {
  position: relative;
  align-items: center;
  height: pxToRem(50);
  background-color: #fff;
  .back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(50);
    height: pxToRem(50);
    .icon {
      width: pxToRem(20);
      height: pxToRem(20);
    }
  }
  .title {
    height: pxToRem(50);
    margin: 0 pxToRem(50);
    color: #555;
    font-size: pxToRem(18);
    line-height: pxToRem(50);
    text-align: center;
  }
}
</style>
