/*
 * @Description: In User Settings Editw
 * @Author: your name
 * @Date: 2019-07-15 09:07:05
 * @LastEditTime: 2021-12-07 11:13:36
 * @LastEditors: zhengdaoyang
 */
const RESULT = () => import("@/views/result/result.vue");
const customResult = () => import("@/views/result/customResult.vue");

const basePath = "/result";
export default [
  // 结果页面
  {
    path: basePath + "/result",
    name: "result",
    component: RESULT,
    meta: {
      title: "结果",
    },
  },
  {
    path: basePath + "/custom-result",
    name: "customResult",
    component: customResult,
    meta: {
      title: "支付结果",
    },
  },
  {
    path: basePath + "/add_Adviser",
    name: "add_Adviser",
    component: () => import("@/views/result/add_Adviser.vue"),
    meta: {
      title: "添加顾问",
    },
  },
  {
    // 指标异常复诊金导流，添加顾问
    path: basePath + "/healthGold_addAdviser",
    name: "healthGold_addAdviser",
    component: () => import("@/views/result/healthGold_addAdviser.vue"),
    meta: {
      title: "添加顾问",
    },
  },
  {
    path: basePath + "/interpret_result",
    name: "interpret_result",
    component: () => import("@/views/result/buyInterpretResult.vue"),
    meta: {
      title: "结果",
    },
  },
];
