/*
 * @Author: 王浩
 * @Date: 2021-06-17 19:59:34
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-12-06 23:31:55
 * 常用工具方法
 */

interface CacheScript {
  [key: string]: Promise<void>;
}

const cacheScript: CacheScript = {};
// 加载脚本
export function loadScript(url: string): Promise<void> {
  // 如果已经加载过相同脚本，则不再加载
  if (cacheScript[encodeURIComponent(url)])
    return cacheScript[encodeURIComponent(url)];

  // 新加载脚本缓存中记录
  cacheScript[encodeURIComponent(url)] = new Promise((resolve, reject) => {
    const oDom = document.createElement("script");
    oDom.onload = function () {
      resolve();
    };

    oDom.onerror = function () {
      reject(new Error());
    };
    oDom.src = url;

    const oHead = document.querySelector("head");
    if (oHead) {
      oHead.appendChild(oDom);
    }
  });

  return cacheScript[encodeURIComponent(url)];
}

const cacheCss: CacheScript = {};
// 加载 CSS
export function loadCss(url: string): Promise<void> {
  // 如果已经加载过相同脚本，则不再加载
  if (cacheCss[encodeURIComponent(url)])
    return cacheCss[encodeURIComponent(url)];

  // 新加载脚本缓存中记录
  cacheCss[encodeURIComponent(url)] = new Promise((resolve, reject) => {
    const oDom = document.createElement("link");
    oDom.rel = "stylesheet";
    oDom.onload = function () {
      resolve();
    };

    oDom.onerror = function () {
      reject(new Error());
    };
    oDom.href = url;

    const oHead = document.querySelector("head");
    if (oHead) {
      oHead.appendChild(oDom);
    }
  });

  return cacheCss[encodeURIComponent(url)];
}

/**
 * 发布订阅模式
 */
export class EventEmitter {
  list: {
    [key: string]: any;
  };
  instance: null | EventEmitter;
  constructor() {
    this.list = {};
    this.instance = null;
  }

  //订阅事件name,type = 0表示永久订阅 =1表示一次订阅
  on(name: string, fn: unknown, type = 0): void {
    //判断事件是否曾被订阅
    if (!this.list[name]) {
      this.list[name] = [];
    }
    this.list[name].push([fn, type]); //监听函数插入该事件列表
  }

  //订阅一次触发后删除
  once(name: string, fn: unknown): void {
    this.on(name, fn, 1);
  }

  //发布
  emit(name: string): void {
    const fns = this.list[name]; //取出事件
    //获取参数列表
    // eslint-disable-next-line
    const args = [].slice.call(arguments, 1);
    //判断监听函数是否存在
    if (!fns || fns.length == 0) {
      return;
    }
    for (let i = fns.length - 1; i >= 0; i--) {
      const fn = fns[i];

      fn[0] && fn[0](...args);
      if (fn[1] == 1) {
        this.remove(name, fn[0], 1);
      }
    }
  }

  //删除事件
  remove(name: string, fn: unknown, type = 0): void {
    if (!name) return;
    const fns = this.list[name];

    //未传入监听函数，取消全部
    if (!fn) {
      this.list[name] = [];
    } else {
      for (let i = fns.length - 1; i >= 0; i--) {
        const targetFn = fns[i];
        if (fn === targetFn[0] && type === 1) {
          fns.splice(i, 1);
        }
      }
    }
  }
}

/**
 * 获取url参数
 * @param {String} name 键
 */
export function getQueryString(name: string): string | null {
  const reg = `(^|&)${name}=([^&]*)(&|$)`;
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

/**
 * 获取 Cookie
 * @param cookieKey
 * @returns
 */
export function getCookie(cookieKey: string): string | null {
  const reg = new RegExp("(^| )" + cookieKey + "=([^;]*)(;|$)");
  const arr = document.cookie.match(reg);
  if (arr) {
    return arr[2];
  } else {
    return null;
  }
}

/**
 * 创建一个节流函数 time ms内仅执行一次
 * @param fn 回调函数
 * @param time
 * @returns
 */
export function throttle(fn: any, time: number) {
  let canRun = true;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line
    const context = this;
    // eslint-disable-next-line
    const args = arguments;
    if (!canRun) return;
    canRun = false;
    setTimeout(() => {
      fn.apply(context, args);
      canRun = true;
    }, time);
  };
}

/**
 * 创建一个防抖函数 delay ms内没有再次操作才会触发
 * @param fn 回调函数
 * @param delay
 * @returns
 */
export function debounce(fn: any, delay: number) {
  let timer = -1;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
}

export function getType(data: unknown): string {
  return Object.prototype.toString.call(data).slice(8, -1);
}

/**
 * 是否为对象
 * @param data
 * @returns Boolean
 */
export function isObject(data: unknown): boolean {
  return getType(data) === "Object";
}

/**
 * 是否为数组
 * @param data
 * @returns Boolean
 */
export function isArray(data: unknown): boolean {
  return getType(data) === "Array";
}

/**
 * 是否为 FormData
 * @param data
 * @returns FormData
 */
export function isFormData(data: unknown): boolean {
  return getType(data) === "FormData";
}

// 用于将rem单位转换为px单位，常用于组件的内联样式，比如 <van-tabs :line-width="$rem2px(0.04)"> </van-tabs>
export const rem2px = (rem) => {
  return parseInt(document.documentElement.style.fontSize) * rem + "px";
};

/**
 * 数组分割
 *
 * @category Array
 * @param {Array} array The array to process.
 * @param {number} [size=1] The length of each chunk
 * @param- {Object} [guard] Enables use as an iteratee for methods like `_.map`.
 * @returns {Array} Returns the new array of chunks.
 * @example
 *
 * chunk(['a', 'b', 'c', 'd'], 2);
 * // => [['a', 'b'], ['c', 'd']]
 *
 * chunk(['a', 'b', 'c', 'd'], 3);
 * // => [['a', 'b', 'c'], ['d']]
 */

export function chunk(array, size = 1) {
  const length = array == null ? 0 : array.length;
  if (!length || size < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = Array(Math.ceil(length / size));

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += size));
  }
  return result;
}
