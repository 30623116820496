/*
 * @Author: 王浩
 * @Date: 2021-07-06 17:05:29
 * @Last Modified by: 王浩
 * @Last Modified time: 2022-03-07 14:52:40
 * 全局属性定义专用插件
 */
import Vue, { PluginObject } from "vue";
import {
  setWXSDK,
  rem2px,
  isWechat,
  loadCss,
  abTestByUserId,
  getCookie,
  getUa,
  getPlatformType,
  getBizType,
  getSzpl,
} from "@/module/tools";
import CreateAPI from "vue-create-api";
import VueAwesomeSwiper from "vue-awesome-swiper";
import preview from "vue-photo-preview"; // 图片预览工具
import lottie from "vue-lottie";
import "vue-photo-preview/dist/skin.css";
import xss from "xss"; // xss过滤
import Lazyload from "vue-lazyload";

const GlobalVarlugin: PluginObject<Vue> = {
  install(Vue) {
    // 加载 Vant 样式
    loadCss("https://static.shanzhen.com/js/vant@2.12/lib/index.min.css");

    // 注册全局指令
    // img.shanzhen.com
    Vue.filter("oldImg", (val: string) => {
      return process.env.VUE_APP_IMG_URL + val;
    });
    // static.shanzhen.com
    Vue.filter("img", (val: string) => {
      return process.env.VUE_APP_STATIC_URL + val;
    });
    Vue.filter("asgard", (val: string) => {
      return process.env.VUE_APP_ASGARD_URL + val;
    });
    Vue.filter("att", (val: string) => {
      return process.env.VUE_APP_ATT_URL + val;
    });
    Vue.filter("price", (value: any) => {
      if (!value || isNaN(value)) return value;
      if (parseFloat(value) <= 0) return 0;
      const _value = (parseFloat(value) / 1000).toFixed(2);
      if (_value.endsWith(".00")) {
        return parseInt(_value);
      }
      return _value;
    });

    // 添加微信ready方法
    Vue.prototype.$wxReady = setWXSDK; // 添加微信ready方法
    Vue.prototype.$toast = vant.Toast;
    Vue.prototype.$dialog = vant.Dialog;
    Vue.prototype.$rem2px = rem2px;
    Vue.prototype.$isWechat = isWechat();
    Vue.prototype.$xss = xss;
    Vue.prototype.$event = new Vue();
    Vue.prototype.$abTestByUserId = abTestByUserId;
    Vue.prototype.$toLogin = (backUrl = "") => {
      if (backUrl) {
        backUrl =
          backUrl.indexOf("http") > -1
            ? backUrl
            : `${location.protocol}//${location.host}${backUrl}`;
      }
      if (process.env.NODE_ENV === "development") {
        location.href =
          process.env.VUE_APP_LOGIN_HOST +
          `/wap/login/login?_szlogout=1&activityCode=ACT518712466&szpl=${getSzpl()}&backUrl=${encodeURIComponent(
            backUrl || location.href
          )}&configDir=custom`;
      } else {
        location.href = `https://m.shanzhen.com/wap/login/login?_szlogout=1&activityCode=ACT518712466&szpl=${getSzpl()}&backUrl=${encodeURIComponent(
          backUrl || location.href
        )}&configDir=custom`;
      }
    };

    Vue.use(VueAwesomeSwiper /* { default options with global component } */);
    Vue.use(preview);
    Vue.component("lottie", lottie);

    // 注册懒加载
    Vue.use(Lazyload, {
      preLoad: 1.3,
      // error: './assets/images_copy/cache/avatar.jpg', // 不添加为空白
      // loading: './assets/images_copy/cache/avatar.jpg', // 不添加为空白
      attempt: 2,
    });

    // createAPI 主要用在支付“支付宝引导弹窗”上
    Vue.use(CreateAPI, {
      componentPrefix: "szc-",
      apiPrefix: "$create-",
    });

    // 处理微信软键盘弹出后页面无法回弹问题
    Vue.prototype.$scrollBy = (x = 0, y = 0) => {
      window.scrollBy(x, y);
    };

    // 获取部分公共属性
    Vue.prototype.$getBizType = getBizType;
    Vue.prototype.$getPlatformType = getPlatformType;
    Vue.prototype.$ua = getUa();
    Vue.prototype.$getCookie = getCookie;

    // 公共资源
    Vue.prototype.$global = {
      IMG_HOST: "https://static.shanzhen.com/",
      staticImgHome: "https://static.shanzhen.com/images/wap/home/v2/", // 对应home的图片地址
      staticImgGoodsDetail:
        "https://static.shanzhen.com/images/wap/goods-detail/assets/",
    };
  },
};

export default GlobalVarlugin;
