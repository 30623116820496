import { Module } from "vuex";
import { RootState } from "@/store/types";

interface GlobalState {
  [key: string]: any;
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    orderStatus: [
      {
        value: "ALL",
        label: "全部",
      },
      {
        value: "NEED_PAY",
        label: "待付款",
      },
      {
        value: "CAN_RESERVE",
        label: "待预约",
      },
      {
        value: "RESERVED",
        label: "已预约",
      },
      {
        value: "REPORT_OUT",
        label: "已出报告",
      },
    ],
  },
  getters: {
    orderStatus: (state) => state.orderStatus,
  },
  mutations: {},
  actions: {},
};

export default GlobalModule;
