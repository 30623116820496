/*
 * @Author: 王浩
 * @Date: 2021-06-18 15:15:26
 * @Last Modified by: nordon
 * @Last Modified time: 2021-09-06 09:31:00
 * 自动绑定登录态 适用于统一登录，支付登录态绑定等
 */
import Vue from "vue";
import { Route } from "vue-router";

const middleware = {
  mode: "interval", // once (仅页面初始化时执行一次) | interval (循环执行)
  fn(to: Route): Promise<void> {
    return new Promise((resolve) => {
      const { user } = to.query;
      if (user && user.length === 32) {
        delete to.query.user;
        try {
          Vue.prototype.$api
            .get("/is/wap/pay/pre_back_query", { user })
            .finally(() => {
              let url = location.pathname;
              const queryString = Object.keys(to.query)
                .map((attr) => `${attr}=${to.query[attr]}`)
                .join("&");

              if (queryString) {
                url += `?${queryString}`;
              }
              location.replace(url);
            });
        } catch (err) {
          resolve();
        }
      } else {
        resolve();
      }
    });
  },
};

export default middleware;
