/*
 * @Author: 王浩
 * @Date: 2021-06-09 17:46:10
 * @Last Modified by: 王浩
 * @Last Modified time: 2022-03-10 11:08:20
 * 注意该页面内容请勿修改，以防止后续架构升级时无法跟上版本
 * 差异化配置请到 src\module\plugins\api.ts 中修改
 */
import szIndexedDB from "sz-indexdb";

// --------------------- 声明开始
interface Config extends AxiosRequestConfig {
  [key: string]: any;
}

type resConfig = Promise<SZResponse>;

interface SZData {
  [key: string]: any;
}
interface SZResponse {
  [key: string]: any;
}

interface SZApiOptions extends AxiosRequestConfig {
  cache?: boolean; // 是否缓存接口数据
}
// --------------------- 声明结束

import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

const BASE_CONFIG = {
  withCredentials: true, // 跨域时是否发送 Cookie
  timeout: 9500, // 超时时间 ms
};

// 数据库相关配置，用于 API 模块的 cache 功能
const DB_CONFIG = {
  name: "SZ_API_CACHE",
  version: 1,
  cacheTime: 300 * 1000,
  objectStoreList: [
    {
      name: "DATA", // 数据对象名称
      keyPath: "primaryKey", // 主键
      autoIncrement: false, // 主键是否自增
    },
  ],
};

// const $db = new szIndexedDB(DB_CONFIG);

// 基础方法封装
class Base {
  private config: Config;
  private instance: AxiosInstance;
  private $db: any;
  public interceptors; // 外部仅允许操作实例的拦截器，其它不允许操作

  constructor(config = {}) {
    this.$db = new szIndexedDB(DB_CONFIG);
    this.config = Object.assign(BASE_CONFIG, config);
    this.instance = axios.create(this.config);
    this.interceptors = this.instance.interceptors;
  }

  // 框架基础方法开始
  ajax(url: string, opts?: SZApiOptions): resConfig {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    // 这里需要缓存和接口调用做竞速
    const request = this.instance
      .request({
        ...opts,
        url,
        cancelToken: source.token,
      })
      .then((res) => {
        // 这里需要进行一些额外操作

        // 缓存接口数据
        // @ts-ignore
        if (opts?.cache && res.canCache) {
          return this.handleCacheRes(url, opts, res);
        }

        return res;
      });
    const key = this.generateCacheKey(url, opts);
    const cache = this.queryCacheData(key);

    return Promise.race([request, cache]).finally(() => {
      // 如果是缓存先取到，则取消接口
      // 如果是接口先返回，取消也不影响结果
      source.cancel();
    });
  }

  get(url: string, params?: SZData, opts: SZApiOptions = {}): resConfig {
    const opt: AxiosRequestConfig = {
      ...opts,
      method: "get",
      params,
    };
    return this.ajax(url, opt);
  }

  post(url: string, data?: SZData, opts: SZApiOptions = {}): resConfig {
    const opt: AxiosRequestConfig = {
      ...opts,
      method: "post",
      data,
    };

    return this.ajax(url, opt);
  }

  /**
   *  serverless（get方式请求）
   *  name：对应node项目 model目录里每个接口的名字
   * 例如： serverlessByGet('mkt_canUseCoupon',xxx)请求的是node中， app/model/mktSystem/index.js中 mkt_canUseCoupon这个方法
   */
  slget(name: string, data?: SZData, opts: SZApiOptions = {}): resConfig {
    const url = `/is/sl/${name}`;
    return this.get(url, data, opts);
  }

  // 同上
  slpost(name: string, data?: SZData, opts: SZApiOptions = {}): resConfig {
    const url = `/is/sl/${name}`;
    return this.post(url, data, opts);
  }

  // all() {}

  // slall() {}

  // 上传接口
  upload(url: string, data: SZData = {}, opts: SZApiOptions = {}): resConfig {
    const formData = new FormData();

    Object.keys(data).forEach((attr) => {
      formData.append(attr, data[attr]);
    });

    if (opts.headers) {
      opts.headers["Content-Type"] = "multipart/form-data";
    } else {
      opts.headers = {
        "Content-Type": "multipart/form-data",
      };
    }

    return this.post(url, formData, opts);
  }

  /**
   * 查询缓存数据
   * @param key
   */
  queryCacheData(key: string): resConfig {
    return new Promise((resolve) => {
      const startTime = performance.now();
      // 缓存数据
      this.$db.ready(() => {
        const endTime = performance.now();
        console.log(`[DB 初始化耗时] INIT ${endTime - startTime}ms`);
        this.$db.DATA.get(key)
          .then((cacheData: any) => {
            console.log("缓存数据为", cacheData);
            resolve(cacheData.data);
          })
          .catch((err: Error) => {
            console.log(err);
          });
      });
    });
  }
  /**
   * 处理/存储缓存数据
   * @param url
   * @param opts
   * @param res
   * @returns
   */
  handleCacheRes(
    url: string,
    opts: SZApiOptions,
    res: AxiosResponse
  ): AxiosResponse {
    const key = this.generateCacheKey(url, opts);
    const startTime = performance.now();
    // 缓存数据
    this.$db.ready(() => {
      const endTime = performance.now();
      console.log(`[DB 初始化耗时] ${endTime - startTime}ms`);
      this.$db.DATA.put({
        primaryKey: key,
        data: res,
      });
    });

    return res;
  }

  /**
   * 生成缓存 Key (已编码)
   * @param url
   * @param opts
   * @returns
   */
  generateCacheKey(url: string, opts: SZApiOptions = {}): string {
    let endKey;
    switch (opts.method?.toLowerCase()) {
      case "get":
        endKey = JSON.stringify(opts.params || {});
        break;
      case "post":
        endKey = JSON.stringify(opts.data || {});
        break;
    }

    return encodeURIComponent(`${url}${endKey || ""}`);
  }

  // 框架公用方法开始
  /**
   * 微信登录
   * @param data
   * @returns
   */
  wxLogin(data: SZData): resConfig {
    return this.post("/is/user/wx_login", data);
  }

  /**
   * 支付宝生活号登录
   * @param data
   * @returns
   */
  alipayLogin(data: SZData): resConfig {
    return this.post("/is/user/alipay_login", data);
  }

  /**
   * 更新cookies登录态
   * @returns
   */
  refreshLoginStatus(): Promise<void> {
    return new Promise((resolve) => {
      this.get("/is/refreshLoginStatus").finally(() => {
        resolve();
      });
    });
  }

  /**
   * 获取微信分享配置
   * @param data
   * @returns
   */
  getShareConfig(data: SZData): resConfig {
    return this.get("/is/activity/share_activity/page", data);
  }

  /**
   * 获取微信SDK配置
   * @returns
   */
  getSdkConfig(): resConfig {
    return this.post("/is/wx/get_sdk_config");
  }
}

export default Base;
